import { Divider, Stack, Card, CardBody } from "@chakra-ui/react";
import { useAuth } from "auth/hooks/use-auth";
import { CheckboxForward } from "common/components/checkbox-forward";
import { InputForward } from "common/components/input-forward";
import { SelectForward } from "common/components/select-forward";
import { useSnack } from "common/hooks/use-snack";
import {
  getDateStringValidator,
  getPhoneNumberValidator,
  getRequiredValidator,
} from "common/validators";
import { countries } from "countries";
import { format, parse } from "date-fns";
import { Button } from "design-system/button";
import { localeState } from "i18n/atoms";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { updateProfile } from "user/endpoints";
import { HOME_PATH } from "user/routes";
import { Profile } from "user/types";

export interface ProfileFormData
  extends Omit<Profile, "dateOfBirth" | "_id" | "roles"> {
  dateOfBirth: string;
}

type FormProps = ProfileFormData;

const dateFormat = "dd-MM-yyyy";

export const ProfileForm: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const locale = useRecoilValue(localeState);
  const { profile } = useAuth();
  const { successMessage } = useSnack();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    register,
    reset,
    trigger,
    formState: { isValid, isDirty, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      language: profile?.language,
      gender: profile?.gender,
      street: profile?.street,
      postcode: profile?.postcode,
      city: profile?.city,
      country: profile?.country,
      informByEmail: !!profile?.informByEmail,
      phoneNumber: profile?.phoneNumber,
      dateOfBirth: profile?.dateOfBirth
        ? format(new Date(profile.dateOfBirth), dateFormat)
        : "",
    },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  const { mutate, isLoading } = useMutation<any, any, FormProps, any>(
    async (data) => {
      const newProfile = await updateProfile(profile?.id!, {
        ...data,
        dateOfBirth: format(
          parse(data?.dateOfBirth, dateFormat, new Date()),
          "yyyy-MM-dd"
        ),
      });

      return newProfile;
    },
    {
      onSuccess: (result) => {
        queryClient.setQueryData("FETCH_PROFILE", result);
        successMessage(t("snack.updated"));
        reset(result);
        navigate(HOME_PATH);
      },
    }
  );

  const onSubmit = (data: FormProps) => {
    mutate(data as any);
  };

  return (
    <Card>
      <CardBody>
        <Stack as="form" spacing={4}>
          <InputForward
            {...register("firstName", {
              ...getRequiredValidator(),
            })}
            errors={errors}
            placeholder={t("placeholder.firstName")}
          />
          <InputForward
            {...register("lastName", {
              ...getRequiredValidator(),
            })}
            errors={errors}
            placeholder={t("placeholder.lastName")}
          />

          <InputForward
            isReadOnly
            isDisabled
            name="Email"
            errors={errors}
            placeholder="E-Mail"
            value={profile?.email}
          />

          <div>
            <InputForward
              {...register("dateOfBirth", {
                ...getDateStringValidator(),
              })}
              errors={errors}
              placeholder={t("placeholder.dateOfBirth")}
              label={t("placeholder.dateOfBirthLabel")}
            />
            <small>{t("datebirth_format")}</small>
          </div>

          <SelectForward
            {...register("gender", {
              ...getRequiredValidator(1),
            })}
            placeholder={t("placeholder.gender")}
            options={[
              { label: t("gender.male"), value: "M" },
              { label: t("gender.female"), value: "F" },
              { label: t("gender.transgender"), value: "T" },
              { label: t("gender.non_binary"), value: "NB" },
              { label: t("gender.prefer_not_respond"), value: "NR" },
            ]}
            errors={errors}
          />

          <SelectForward
            {...register("language", {
              ...getRequiredValidator(2),
            })}
            placeholder={t("placeholder.language")}
            options={[
              { label: t("lang.english"), value: "EN" },
              { label: t("lang.dutch"), value: "NL" },
            ]}
            errors={errors}
          />

          <Divider />
          <SelectForward
            {...register("country", {
              ...getRequiredValidator(2),
            })}
            placeholder={t("placeholder.country")}
            options={countries.map((country) => ({
              label: country[locale],
              value: country.alpha2.toUpperCase(),
            }))}
            errors={errors}
          />

          <InputForward
            {...register("street", {
              ...getRequiredValidator(),
            })}
            errors={errors}
            placeholder={t("placeholder.street")}
          />

          <InputForward
            {...register("postcode", {
              ...getRequiredValidator(),
            })}
            errors={errors}
            placeholder={t("placeholder.postcode")}
          />

          <InputForward
            {...register("city", {
              ...getRequiredValidator(),
            })}
            errors={errors}
            placeholder={t("placeholder.city")}
          />

          <Divider />

          <InputForward
            {...register("phoneNumber", {
              ...getPhoneNumberValidator(),
            })}
            errors={errors}
            placeholder={t("placeholder.phoneNumber")}
          />
          <CheckboxForward
            {...register("informByEmail")}
            errors={errors}
            label={<>{t("signup.emailPermission")}</>}
          />

          <Button
            disabled={!(isValid && isDirty)}
            isLoading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t("action.save")}
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};
