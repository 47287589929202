import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";

const position = "top";
const duration = 2000;

export const useSnack = () => {
  const toast = useToast();
  const successMessage = useCallback(
    (message: string) =>
      toast({
        description: message,
        status: "success",
        position,
        duration,
      }),
    [toast]
  );
  const errorMessage = useCallback(
    (message: string) =>
      toast({
        description: message,
        status: "error",
        position,
        duration,
      }),
    [toast]
  );
  const infoMessage = useCallback(
    (message: string) =>
      toast({ description: message, status: "info", position, duration }),
    [toast]
  );

  return {
    successMessage,
    errorMessage,
    infoMessage,
  };
};
