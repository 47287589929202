import { supxios } from "api";
import { Location, Slot } from "./types";
import { fakeRequest } from "./utils";

export const FETCH_AREAS_QUERY = "FETCH_AREAS";
export const fetchAreas = async () => {
  return (await supxios.get("/areas")).data;
};

export const FETCH_LOCATIONS_QUERY = "FETCH_LOCATIONS";
export const fetchLocations = async () => {
  return (await supxios.get("/locations")).data;
};

export const FETCH_LOCATIONS_BY_AREA_ID_QUERY =
  "FETCH_LOCATIONS_BY_AREA_ID_QUERY";
export const fetchLocationsByAreaId = async (id: string) => {
  return (await supxios.get(`/areas/${id}/locations/`)).data;
};

export const FETCH_LOCATION_BY_ID_QUERY = "FETCH_LOCATION_BY_ID_QUERY";
export const fetchLocationById = async (id: string): Promise<Location> => {
  return (await supxios.get(`/locations/${id}`)).data;
};

export const FETCH_SLOTS_QUERY = "FETCH_SLOTS";
export const fetchSlots = (): Promise<Slot[]> => {
  return fakeRequest(
    [
      { time: "10:00", availability: 6 },
      { time: "10:30", availability: 5 },
      { time: "11:00", availability: 3 },
      { time: "11:30", availability: 1 },
      { time: "12:00", availability: 6 },
      { time: "12:30", availability: 4 },
    ],
    1000
  );
};

export const getAvailableSlots = async (
  locationId: string,
  date: string,
  duration: number,
  boards: number
): Promise<{ time: number; availability: number }[]> => {
  const resp = await supxios.post("/bookings/slots", {
    locationId,
    date,
    duration,
    boards,
  });

  return resp.data;
};

export const addBooking = async (booking: Partial<any>, isManual = false) => {
  if (isManual) {
    return (await supxios.post("/admin/bookings", booking)).data;
  }
  return (await supxios.post("/bookings", booking)).data;
};

export const FETCH_UPCOMING_BOOKINGS_QUERY = "FETCH_UPCOMING_BOOKINGS_QUERY";
export const fetchUpcomingBookings = async (): Promise<any[]> => {
  return (await supxios.get("/bookings/upcoming/list")).data;
};
