import { Checkbox as ChakraCheckbox, CheckboxProps } from "@chakra-ui/react";

interface Props extends CheckboxProps {
  label: string | JSX.Element;
}

export const Checkbox: React.FC<Props> = ({ label, ...props }) => {
  return (
    <ChakraCheckbox size="md" {...props}>
      {label}
    </ChakraCheckbox>
  );
};
