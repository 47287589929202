import { areasState, locationsState } from "common/atoms";
import {
  FETCH_AREAS_QUERY,
  FETCH_LOCATIONS_BY_AREA_ID_QUERY,
  FETCH_LOCATION_BY_ID_QUERY,
} from "common/endpoints";
import { Area, Location } from "common/types";
import { useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import {
  bookingState,
  BookingStateAtom,
  INITIAL_BOOKING_SESSION,
} from "user/atoms";
import { isBookingSessionComplete } from "user/utils";

export const useBookingFlow = () => {
  const [booking, setBookingState] = useRecoilState(bookingState);

  const isComplete = isBookingSessionComplete(booking);
  const [areas] = useRecoilState(areasState);
  const [locations] = useRecoilState(locationsState);
  const queryClient = useQueryClient();
  const setBooking = (value: BookingStateAtom) => {
    const merge = {
      ...booking,
      ...value,
    };

    const newValue = value ? merge : INITIAL_BOOKING_SESSION;

    setBookingState(newValue);
  };
  const resetBooking = () => {
    setBookingState(INITIAL_BOOKING_SESSION);
  };

  const canShowBookingReview = !!booking?.areaId;

  const getLocationFromUid = (id: string) => {
    const cachedLocations = queryClient.getQueryData<Location[]>([
      FETCH_LOCATIONS_BY_AREA_ID_QUERY,
      booking.areaId,
    ]);

    const cachedLocation = queryClient.getQueryData<Location>([
      FETCH_LOCATION_BY_ID_QUERY,
      id,
    ]);

    if (cachedLocation) {
      return cachedLocation;
    }

    if (cachedLocations) {
      return cachedLocations.find((location) => location.id === id);
    }

    return null;
  };

  const getAreaFromUid = (id: string) => {
    const cachedAreas = queryClient.getQueryData([FETCH_AREAS_QUERY]);

    if (cachedAreas) {
      return (cachedAreas as Area[]).find((area: Area) => area.id === id);
    }

    return null;
  };

  return {
    booking,
    setBooking,
    resetBooking,
    areas,
    locations,
    isComplete,
    canShowBookingReview,
    getLocationFromUid,
    getAreaFromUid,
  };
};
