import { Link, LinkProps } from "@chakra-ui/react";
import { NavLink, NavLinkProps } from "react-router-dom";

export const SLink: React.FC<LinkProps & NavLinkProps> = ({
  children,
  ...props
}) => (
  <Link as={NavLink} {...props}>
    {children}
  </Link>
);
