import { Card, CardBody, Divider, Stack } from "@chakra-ui/react";
import { supxios } from "api";
import { SIGN_UP_PATH, FORGOT_PASSWORD_PATH } from "../../routes";
import { useSnack } from "common/hooks/use-snack";
import { getEmailValidator, getRequiredValidator } from "common/validators";
import { Button } from "design-system/button";
import { Text16 } from "design-system/text";
import { PageTitle } from "design-system/titles";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useAuth } from "auth/hooks/use-auth";
import { InputForward } from "common/components/input-forward";
import { SLink } from "design-system/s-link";
import { TOKEN_STORAGE, setInStorage } from "common/utils";

interface FormProps {
  email: string;
  password: string;
}

export const SignInView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const { setToken } = useAuth();
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate: onSubmit, isLoading } = useMutation<any, any, FormProps, any>(
    async (data) => {
      const resp = await supxios.post("auth/signin", data);

      return resp.data;
    },
    {
      onSuccess: (resp) => {
        setInStorage(TOKEN_STORAGE, resp.token);
        setToken(resp.token);
        successMessage(t("snack.signin"));
      },
    }
  );

  return (
    <Stack>
      <PageTitle>{t("pageTitle.signIn")}</PageTitle>
      <Card>
        <CardBody>
          <Stack spacing={4} as="form">
            <InputForward
              placeholder={t("placeholder.email")}
              errors={errors}
              type="email"
              {...register("email", { ...getEmailValidator() })}
            />

            <InputForward
              placeholder={t("placeholder.password")}
              label={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <div>{t("placeholder.password")} </div>
                  <SLink textDecor="underline" to={FORGOT_PASSWORD_PATH}>
                    {t("action.reset")}
                  </SLink>
                </Stack>
              }
              errors={errors}
              type="password"
              {...register("password", { ...getRequiredValidator() })}
            />

            <Button
              type="submit"
              onClick={handleSubmit((data) => onSubmit(data))}
              disabled={!isValid}
              isLoading={isLoading}
            >
              {t("pageTitle.signIn")}
            </Button>
            <Divider />
            <Text16 textAlign="center">
              {t("form.noAccount")}{" "}
              <SLink textDecor="underline" to={SIGN_UP_PATH}>
                {t("action.signup")}
              </SLink>
            </Text16>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};
