import { Button, ModalBody, ModalFooter } from "@chakra-ui/react";
import { ModalProps } from "common/types";
import { Privacy } from ".";

export const PrivacyModal: React.FC<ModalProps> = ({ handleClose }) => {
  return (
    <>
      <ModalBody>
        <Privacy />
      </ModalBody>
      <ModalFooter>
        <Button variant="ghost" onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};
