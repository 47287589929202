import {
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

export const PrivacyNl = () => {
  return (
    <div>
      <Heading as="h1" size="md" mt={4}>
        Privacy Verklaring Sup Sup Club
      </Heading>
      <Stack spacing={4} mt={4}>
        <Text>
          Op het moment dat je bij de Sup Sup Club een SUPboard wilt huren, of
          als je een bestelling doet via onze website (www.supsupclub.com),
          hebben wij een aantal persoonsgegevens van je nodig. Waarom wij deze
          persoonsgegevens nodig hebben en wat wij met deze gegevens doen,
          leggen wij graag uit in deze Privacy Verklaring.
        </Text>
        <Text>
          De Sup Sup Club vindt privacy belangrijk is dus zeer betrokken bij het
          beschermen van de privacy van haar Deelnemers. Persoonsgegevens worden
          door ons dan ook zorgvuldig opgeslagen, verwerkt en beveiligd. De Sup
          Sup Club leeft alle verplichtingen voortvloeiende uit de Algemene
          Verordening Gegevensbescherming (AVG) (Regulation (EU) 2016/679) en
          andere privacywetgeving zorgvuldig na. De Sup Sup Club B.V. en haar
          kantoor is gevestigd te Zandhoek 22 (1013 KT) te Amsterdam, haar Sup
          Sup Club verhuur stations staan verspreid over meerdere plekken door
          heel Nederland (zie de specifieke adressen op onze website bij
          locaties).
        </Text>
        <Heading size="md">Welke persoonsgegevens verzamelen wij?</Heading>
        <UnorderedList>
          <ListItem>voor- en achternaam;</ListItem>
          <ListItem>geslacht;</ListItem>
          <ListItem>geboortedatum;</ListItem>
          <ListItem>e-mail adres;</ListItem>
          <ListItem>(mobiel) telefoonnummer;</ListItem>
          <ListItem>adresgegevens;</ListItem>
          <ListItem>bankgegevens;</ListItem>
          <ListItem>
            informatie rondom je bezoek aan de Sup Sup Club (in- en uitgaande
            tijd van verhuur SUPboards en geboekte lessen);
          </ListItem>
          <ListItem>transactiegegevens uit bestellingen; en</ListItem>
          <ListItem>
            IP-adressen besturingssysteem en type browser en tijdsstempels.
          </ListItem>
        </UnorderedList>
        <Text>Wij gebruiken deze gegevens voor de volgende doeleinden:</Text>
        <UnorderedList>
          <ListItem>
            het opstellen, versturen en opstellen van facturen;
          </ListItem>
          <ListItem>het verstrekken van informatie;</ListItem>
          <ListItem>het verbeteren van onze dienstverlening;</ListItem>
          <ListItem>
            het beantwoorden van vragen die gesteld zijn via bijvoorbeeld de
            website;
          </ListItem>
          <ListItem>
            het eventueel verstrekken van informatie met betrekking tot nieuwe
            producten en diensten; en
          </ListItem>
          <ListItem>
            om aan onze verplichtingen te kunnen voldoen die voortkomen uit
            contracten die we met jou hebben afgesloten, evenals voor
            facturering en levering.
          </ListItem>
        </UnorderedList>
        <Text>
          De grondslag voor de verwerking is het uitvoeren van de overeenkomst
          met jou en het voldoen aan een wettelijke verplichting. Verstrekking
          persoonsgegevens aan derden
        </Text>

        <Text>
          Wij verkopen of verhandelen geen persoonsgegevens en verstrekken geen
          persoonsgegevens aan derden zonder jouw uitdrukkelijke toestemming
          daartoe. Jouw persoonsgegevens zullen uitsluitend worden gedeeld
          indien wij daar wettelijk toe verplicht zijn.
        </Text>
        <Heading size="md">JVerwerkers</Heading>
        <Text>
          Om onze diensten te kunnen verlenen maken we gebruik van andere
          verwerkers, zoals Adyen voor de betalingen. Wij zorgen ervoor dat ook
          onze verwerkers de gegevens verwerken volgens de AVG.
        </Text>

        <Heading size="md">Je rechten</Heading>
        <Text>
          Je hebt altijd recht op inzage in de persoonsgegevens die wij van je
          bewaren. Als deze gegevens onjuist mochten zijn kunt je ons helpen
          deze te corrigeren of je kan vragen om de verwerking te beperken. Ook
          heb je het recht ons te vragen je persoonsgegevens te verwijderen of
          om uw persoonsgegevens op een gegevensdrager aan je ter beschikking te
          stellen. Verzoeken daartoe worden altijd gehonoreerd. Je kunt genoemde
          rechten uitoefenen door een email te sturen naar het contactadres op
          de site. Daarnaast kan je ook altijd bezwaar maken ten de bewerking
          van jouw persoonsgegevens.
        </Text>

        <Heading size="md">Hoe lang bewaren we je persoonsgegevens?</Heading>
        <Text>
          Wij bewaren je persoonsgegevens zolang dat noodzakelijk is voor de
          verwezenlijking van de doeleinden waarvoor wij jouw persoonsgegevens
          verwerken. Nadat je je lidmaatschap hebt opgezegd worden jouw gegevens
          maximaal 7 jaar bewaard.
        </Text>

        <Heading size="md">Beveiliging</Heading>
        <Text>
          Wij treffen vanwege de aard van onze dienstverlening en het vertrouwen
          dat je in ons moet kunnen stellen, rekening houdend met de stand van
          de techniek de uitvoeringskosten, passende technische en
          organisatorische maatregelen om te waarborgen dat de verwerking van
          persoonsgegevens plaatsvindt in overeenstemming met de AVG.
        </Text>

        <Heading size="md">Autoriteit Persoonsgegevens</Heading>
        <Text>
          Natuurlijk helpen wij je graag verder als je klachten hebt over de
          verwerking van jouw persoonsgegevens. Op grond van de AVG heb je ook
          het recht om een klacht in te dienen bij de Autoriteit
          Persoonsgegevens tegen deze verwerkingen van persoonsgegevens. Je kunt
          hiervoor contact opnemen met de Autoriteit Persoonsgegevens.
        </Text>

        <Heading size="md">Aanpassingen</Heading>
        <Text>
          De Sup Sup Club behoudt zich het recht voor om haar privacyverklaring
          aan te passen en te wijzigen. Neem als je een vraag hebt contact met
          ons op via info@supsupclub.com Deze privacyverklaring is voor het
          laatst aangepast op 1 april 2023.
        </Text>
      </Stack>
    </div>
  );
};
