import { Card, CardBody, Center, Stack } from "@chakra-ui/react";
import { format, startOfDay } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { UnstyledButton } from "design-system/button";
import { PurpleDark05 } from "design-system/colors";
import { Text12 } from "design-system/text";
import { TitleMedium } from "design-system/titles";
import { MinusCircle, PlusCircle } from "phosphor-react";
import { DayPicker } from "react-day-picker";
import { useTranslation } from "react-i18next";
import { useBookingFlow } from "user/hooks/use-booking-flow";
import {
  MAXIMUM_BOARDS_NR,
  MAXIMUM_DURATION,
  MINIMUM_BOARDS_NR,
  MINIMUM_DURATION,
  TIMEZONE,
} from "user/utils";
import { nl } from "date-fns/locale";
import { useRecoilState } from "recoil";
import { localeState } from "i18n/atoms";

interface Props {
  isManual?: boolean;
  nextCallback?: () => void;
  backCallback?: () => void;
}

export const BookingDetails: React.FC<React.PropsWithChildren<Props>> = () => {
  const { t } = useTranslation("common");
  const { booking, setBooking } = useBookingFlow();
  const [locale] = useRecoilState(localeState);

  return (
    <Stack spacing={4}>
      <Stack spacing={4} direction={{ base: "column", md: "row" }}>
        <Stack spacing={2}>
          <div>{t("datepicker.placeholder")}</div>
          <Stack direction={{ base: "column", md: "row" }} spacing={4}>
            <Card
              sx={{
                boxShadow:
                  "4px 4px 1px rgba(0,0,0,0.2), 0px -4px 0px #a9a1fd inset",
              }}
            >
              <CardBody>
                <Center>
                  <DayPicker
                    locale={locale === "nl" ? nl : undefined}
                    weekStartsOn={1}
                    mode="single"
                    fromDate={utcToZonedTime(startOfDay(new Date()), TIMEZONE)}
                    selected={
                      booking.date
                        ? utcToZonedTime(booking.date, TIMEZONE)
                        : undefined
                    }
                    onSelect={(date) => {
                      if (!date) return;

                      setBooking({
                        date: format(date as Date, "yyyy-MM-dd"),
                      });
                    }}
                  />
                </Center>
              </CardBody>
            </Card>

            <Stack
              direction={{ base: "row", md: "column" }}
              justifyContent={{ base: "space-between", md: "flex-start" }}
              flexWrap="wrap"
              spacing={{ base: 0, md: 4 }}
            >
              <Stack spacing={1}>
                <Text12>{t("bookingFlow.boardsNumber")}</Text12>
                <Stack
                  direction="row"
                  alignSelf="flex-start"
                  alignItems="center"
                  justifyContent="space-between"
                  width={130}
                  padding={1}
                  backgroundColor={PurpleDark05}
                  borderRadius={4}
                >
                  <Stack
                    as={UnstyledButton}
                    disabled={booking?.boardsNumber === MINIMUM_BOARDS_NR}
                    onClick={() =>
                      setBooking({
                        boardsNumber:
                          (booking?.boardsNumber || MINIMUM_BOARDS_NR) ===
                          MINIMUM_BOARDS_NR
                            ? MINIMUM_BOARDS_NR
                            : (booking.boardsNumber || MINIMUM_BOARDS_NR + 1) -
                              1,
                      })
                    }
                  >
                    <MinusCircle size={32} color="#fff" />
                  </Stack>
                  <TitleMedium color="#fff">{booking.boardsNumber}</TitleMedium>
                  <Stack
                    as={UnstyledButton}
                    disabled={booking?.boardsNumber === MAXIMUM_BOARDS_NR}
                    onClick={() =>
                      setBooking({
                        boardsNumber:
                          booking?.boardsNumber === MAXIMUM_BOARDS_NR
                            ? booking.boardsNumber
                            : (booking?.boardsNumber || MINIMUM_BOARDS_NR) + 1,
                      })
                    }
                  >
                    <PlusCircle size={32} color="#fff" />
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Text12>{t("bookingFlow.duration")}</Text12>
                <Stack
                  direction="row"
                  alignSelf="flex-start"
                  alignItems="center"
                  justifyContent="space-between"
                  width={130}
                  padding={1}
                  backgroundColor={PurpleDark05}
                  borderRadius={4}
                >
                  <Stack
                    as={UnstyledButton}
                    disabled={booking?.duration === MINIMUM_DURATION}
                    onClick={() =>
                      setBooking({
                        duration:
                          booking?.duration === MINIMUM_DURATION
                            ? MINIMUM_DURATION
                            : (booking.duration || MINIMUM_DURATION + 0.5) -
                              0.5,
                      })
                    }
                  >
                    <MinusCircle size={32} color="#fff" />
                  </Stack>
                  <TitleMedium color="#fff">{booking.duration}h</TitleMedium>
                  <Stack
                    as={UnstyledButton}
                    disabled={booking?.duration === MAXIMUM_DURATION}
                    onClick={() =>
                      setBooking({
                        duration:
                          booking?.duration === MAXIMUM_DURATION
                            ? MAXIMUM_DURATION
                            : (booking?.duration || MINIMUM_BOARDS_NR) + 0.5,
                      })
                    }
                  >
                    <PlusCircle size={32} color="#fff" />
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
