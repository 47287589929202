import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const TextBase = styled(Box)`
  line-height: 1.5;
`;

export const Text16 = styled(TextBase)`
  font-size: 16px;
`;

export const Text14 = styled(TextBase)`
  font-size: 14px;
`;

export const Text12 = styled(TextBase)`
  font-size: 12px;
`;
