import { IconButton, Stack, HStack } from "@chakra-ui/react";
import { ReactComponent as SupHLogo } from "assets/h-logo.svg";
import { List } from "phosphor-react";
import { useSetRecoilState } from "recoil";
import { showSettingsState } from "user/atoms";
import { Link } from "design-system/links";
import { HOME_PATH } from "user/routes";

export const Header: React.FC<React.PropsWithChildren> = () => {
  const setShowSettings = useSetRecoilState(showSettingsState);

  return (
    <Stack
      padding="0 24px"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      width="100%"
    >
      <Link to={HOME_PATH}>
        <SupHLogo style={{ maxWidth: "80%" }} />
      </Link>
      <HStack alignItems="center">
        <IconButton
          aria-label="btn"
          variant="ghost"
          color="white"
          _hover={{ color: "black", bg: "white" }}
          onClick={() => setShowSettings(true)}
          icon={<List size={24} color="currentColor" />}
        ></IconButton>
      </HStack>
    </Stack>
  );
};
