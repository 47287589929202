import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Select,
  SelectProps,
} from "@chakra-ui/react";
import { forwardRef } from "react";
import { InputForwardProps } from "../input-forward";

interface Option {
  label: string;
  value: string;
}

interface SelectForwardProps extends InputForwardProps {
  options: Option[];
}

export const SelectForward = forwardRef<any, SelectForwardProps & SelectProps>(
  ({ errors, options, ...props }, ref) => (
    <FormControl isInvalid={!!errors[props.name]}>
      {props.placeholder && (
        <FormLabel htmlFor={props.name}>{props.placeholder}</FormLabel>
      )}
      <Select ref={ref} {...props}>
        {options.map((option: Option, index: number) => (
          <option key={`option-${index}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      <FormErrorMessage>
        {errors[props.name] && <>{errors[props.name]?.message}</>}
      </FormErrorMessage>
    </FormControl>
  )
);

SelectForward.displayName = "SelectForward";
