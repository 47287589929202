import {
  ChakraProvider,
  DarkMode,
  extendTheme,
  GlobalStyle,
  LightMode,
  ThemeOverride,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { Fonts } from "common/fonts";
import { Purple100 } from "design-system/colors";
import { darken, lighten } from "polished";

const PURPLE = "#7A6BFF";
const PURPLE_LIGHT = lighten(0.15, PURPLE);
const PURPLE_SHADOW = "#3b3383";
const GREEN = "#25d9c7";
const GREEN_LIGHT = lighten(0.15, GREEN);
const GREEN_SHADOW = darken(0.15, GREEN);

const base: ThemeOverride = {
  components: {
    Checkbox: {
      baseStyle: {
        control: {
          border: "1px",
          borderColor: "brand.100",
          position: "relative",
          top: "2px",
          _checked: {
            backgroundColor: "brand.100",
            borderColor: "brand.100",
            _hover: {
              backgroundColor: "brand.100",
              borderColor: "brand.100",
            },
          },
        },
      },
    },
    Select: {
      variants: {
        outline: {
          field: {
            bg: "white",
            color: "gray.800",
            _focus: {
              boxShadow: `0px 0px 0px 2px ${PURPLE_SHADOW}`,
              borderColor: PURPLE_SHADOW,
            },
          },
          icon: {
            color: "gray.800",
          },
        },
        secondary: {
          field: {
            bg: "transparent",
            color: "white",
            boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.3)",
            "::placeholder": {
              color: "rgba(255, 255, 255, 0.5)",
            },
            _hover: {
              boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 1)",
            },
            _focus: {
              boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 1)",
            },
            _invalid: {
              boxShadow: "0px 0px 0px 1px red",
            },
          },
        },
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            bg: "white",
            color: "gray.800",
            _focus: {
              boxShadow: `0px 0px 0px 2px ${PURPLE_SHADOW}`,
              borderColor: PURPLE_SHADOW,
            },
          },
        },
        secondary: {
          field: {
            bg: "transparent",
            color: "white",
            boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 0.3)",
            "::placeholder": {
              color: "rgba(255, 255, 255, 0.5)",
            },
            _hover: {
              boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 1)",
            },
            _focus: {
              boxShadow: "0px 0px 0px 1px rgba(255, 255, 255, 1)",
            },
            _invalid: {
              boxShadow: "0px 0px 0px 1px red",
            },
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        item: {
          color: "black",
          fontSize: "sm",
        },
      },
    },
    Card: {
      variants: {
        elevated: {
          container: {
            bg: "white",
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          marginLeft: 4,
          marginRight: 4,
          color: "black",
        },
      },
    },
    Link: {
      variants: {
        tablerow: {
          textDecoration: "none",
          _hover: {
            textDecoration: "none",
            bg: "gray.100",
          },
        },
        secondary: {
          color: "white",
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "Avenir, Roboto",
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "50px",
      },
      variants: {
        solid: (props) => ({
          bg: mode("brand.200", "gray.200")(props),
          color: mode("white", "gray.800")(props),
          _hover: {
            bg: mode(GREEN_LIGHT, "gray.300")(props),
            color: mode("white", "gray.800")(props),
            _disabled: {
              bg: mode("brand.200", "gray.300")(props),
            },
          },
          _active: {
            bg: mode(GREEN_LIGHT, "gray.300")(props),
            color: mode("white", "gray.800")(props),
          },
        }),
        outline: (props) => ({
          color: mode("brand.100", "gray.800")(props),
          borderColor: mode("brand.100", "gray.800")(props),
          _hover: {
            color: mode("brand.100", "gray.800")(props),
          },
        }),
      },
    },
  },
  styles: {
    global: (props) => ({
      body: {
        backgroundColor: mode("brand.100", "gray.800")(props),
        color: "white",
        fontFamily: "Avenir, Roboto",
      },
    }),
  },
  colors: {
    brand: {
      50: PURPLE_LIGHT,
      100: PURPLE,
      150: PURPLE_SHADOW,
      200: GREEN,
      250: GREEN_SHADOW,
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
};
const extended: ThemeOverride = extendTheme(base);

const daypickerStyles = `
.rdp {
  margin: 0;
  width: 100%;
}
.rdp-months,
.rdp-day,
.rdp-month,
.rdp-table {
  width: 100%;
  max-width: none;
}
.rdp-day_selected:not([disabled]) { 
  background-color: ${Purple100}; 
  color: white;
}
`;

export const Theme: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <ChakraProvider theme={extended}>
      <Fonts />
      <ForceColorMode colorMode={extended.config?.initialColorMode}>
        <style>{daypickerStyles}</style>
        <GlobalStyle />
        {props.children}
      </ForceColorMode>
    </ChakraProvider>
  );
};

interface ForceColorModeProps {
  colorMode: "light" | "dark" | "system" | undefined;
}
export const ForceColorMode: React.FC<
  React.PropsWithChildren<ForceColorModeProps>
> = ({ colorMode, children }) => {
  if (colorMode === "light") {
    return <LightMode>{children}</LightMode>;
  }
  if (colorMode === "dark") {
    return <DarkMode>{children}</DarkMode>;
  }
  return <>{children}</>;
};
