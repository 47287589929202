import { FormControl, Checkbox, CheckboxProps } from "@chakra-ui/react";
import { forwardRef } from "react";
import { InputForwardProps } from "../input-forward";

interface CheckboxForwardProps extends InputForwardProps {}

export const CheckboxForward = forwardRef<
  any,
  CheckboxForwardProps & CheckboxProps
>(({ errors, ...props }, ref) => {
  return (
    <FormControl isInvalid={!!errors[props.name]}>
      <Checkbox ref={ref} alignItems="start" {...props}>
        {props.label || props.placeholder}
      </Checkbox>
    </FormControl>
  );
});

CheckboxForward.displayName = "CheckboxForward";
