import { HStack, Spinner, Stack } from "@chakra-ui/react";
import { ClickableCard } from "common/components/clickable-card";
import { fetchAreas, FETCH_AREAS_QUERY } from "common/endpoints";
import { TitleMedium } from "design-system/titles";
import { Buildings } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { useBookingFlow } from "user/hooks/use-booking-flow";
import { getBookPath } from "user/routes";

interface Props {
  isManual?: boolean;
  nextCallback?: () => void;
  backCallback?: () => void;
}

export const BookingAreas: React.FC<React.PropsWithChildren<Props>> = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { setBooking } = useBookingFlow();
  const { data: areas, isLoading: isLoadingAreas } = useQuery(
    FETCH_AREAS_QUERY,
    () => fetchAreas(),
    {
      staleTime: Infinity,
    }
  );

  if (isLoadingAreas) {
    return <Spinner />;
  }

  return (
    <Stack spacing={4}>
      <HStack spacing={2}>
        <TitleMedium>{t("bookingFlow.areas")}</TitleMedium>
      </HStack>
      {(areas || []).map((area: any) => (
        <ClickableCard
          key={area.id}
          onClick={() => {
            setBooking({ areaId: area.id });
            navigate(getBookPath(`areas/${area.id}`));
          }}
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <Buildings />
            <div>{area.name}</div>
          </Stack>
        </ClickableCard>
      ))}
    </Stack>
  );
};
