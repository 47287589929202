import { Stack } from "@chakra-ui/react";
import { useAuth } from "auth/hooks/use-auth";
import { Navigate, Outlet } from "react-router-dom";

export const BookView: React.FC<React.PropsWithChildren> = () => {
  const { isOnefitAccount } = useAuth();

  if (isOnefitAccount) {
    return <Navigate to="/" />;
  }

  return (
    <Stack>
      <Outlet />
    </Stack>
  );
};
