import { Stack } from "@chakra-ui/react";
import { PageTitle } from "design-system/titles";
import { useTranslation } from "react-i18next";
import { BookingDetails } from "user/components/booking-details";

export const BookingDetailsView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");

  return (
    <Stack>
      <PageTitle>{t("bookingDetails.title")}</PageTitle>
      <Stack spacing={1}>
        <BookingDetails />
      </Stack>
    </Stack>
  );
};
