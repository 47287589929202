import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { Purple10, Purple100 } from "design-system/colors";
import { NavLink } from "react-router-dom";

export const MenuItem = styled(NavLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  flex: 1;
  padding: 0 12px;
  color: ${Purple100};

  &.active {
    background-color: ${Purple10};
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.2;
  }
`;

export const MenuItemText = styled(Box)`
  margin-top: 4px;
`;
