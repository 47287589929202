import { Drawer, Stack, DrawerOverlay, DrawerContent } from "@chakra-ui/react";
import { useAuth } from "auth/hooks/use-auth";
import { useLayoutUtils } from "common/hooks/use-breakpoints";
import { useSnack } from "common/hooks/use-snack";
import { Button } from "design-system/button";
import { TitleSmall } from "design-system/titles";
import { localeState } from "i18n/atoms";
import { SignOut } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { showSettingsState } from "user/atoms";
import { HOME_PATH } from "user/routes";
import { Select } from "@chakra-ui/react";

export const SettingsDrawer: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const { mdUp } = useLayoutUtils();
  const navigate = useNavigate();
  const { setToken, isLoggedIn } = useAuth();
  const { successMessage } = useSnack();
  const qc = useQueryClient();
  const [locale, setLocale] = useRecoilState(localeState);
  const [openDrawer, setOpenDrawer] = useRecoilState(showSettingsState);

  const handleClose = () => {
    setOpenDrawer(false);
  };
  const { mutate, isLoading } = useMutation(
    () => {
      setToken(null);
      return Promise.resolve(true);
    },
    {
      onSuccess: () => {
        successMessage(t("snack.signout"));

        qc.invalidateQueries();
        qc.clear();
        navigate(HOME_PATH);
        handleClose();
      },
    }
  );

  return (
    <Drawer
      placement={mdUp ? "right" : "bottom"}
      isOpen={openDrawer}
      autoFocus={false}
      onClose={handleClose}
    >
      <DrawerOverlay />
      <DrawerContent>
        <Stack spacing={3} padding={3} sx={{ minWidth: { md: 240 } }}>
          <Stack spacing={1}>
            <TitleSmall color="blackAlpha.600">
              {t("placeholder.language")}
            </TitleSmall>
            <Select
              value={locale}
              onChange={(ev) => setLocale(ev.target.value)}
            >
              <option value="en">English</option>
              <option value="nl">Nederland</option>
              <option value="pt">Português</option>
            </Select>
          </Stack>
          {isLoggedIn && (
            <Button
              leftIcon={<SignOut size={21} />}
              isLoading={isLoading}
              onClick={() => mutate()}
            >
              {t("action.signout")}
            </Button>
          )}
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};
