import { Box, Card, CardBody, Divider, Stack } from "@chakra-ui/react";
import { supxios } from "api";
import { SIGN_IN_PATH } from "auth/routes";
import { AxiosResponse } from "axios";
import { modalState } from "common/atoms";
import { CheckboxForward } from "common/components/checkbox-forward";
import { InputForward } from "common/components/input-forward";
import { PrivacyModal } from "common/components/modals/privacy/modal";
import { TCModal } from "common/components/modals/tc/modal";
import { useSnack } from "common/hooks/use-snack";
import { getEmailValidator, getRequiredValidator } from "common/validators";
import { Button } from "design-system/button";
import { ExternalLink } from "design-system/links";
import { PageTitle } from "design-system/titles";
import { MouseEvent } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

interface FormProps {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  tc: boolean;
  privacy: boolean;
  informByEmail: boolean;
}

export const SignUpView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const setModal = useSetRecoilState(modalState);
  const { successMessage } = useSnack();
  const {
    handleSubmit,
    register,
    formState: { isValid, errors },
  } = useForm<FormProps>({
    mode: "onChange",
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      tc: false,
      privacy: false,
      informByEmail: false,
    },
  });

  const { mutate: onSubmit, isLoading } = useMutation<
    AxiosResponse,
    any,
    FormProps,
    any
  >(
    async (data) => {
      const resp = await supxios.post("auth/signup", data);

      return resp;
    },
    {
      onSuccess: () => {
        successMessage(t("snack.signup"));
        navigate(SIGN_IN_PATH);
      },
    }
  );

  const onTCClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setModal({ key: "TC", component: TCModal });
  };

  const onPrivacyClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setModal({ key: "PRIVACY", component: PrivacyModal });
  };

  return (
    <Stack>
      <PageTitle>{t("pageTitle.signUp")}</PageTitle>
      <Box>
        {t("signup_already_account")}.{" "}
        <Link style={{ textDecoration: "underline" }} to={SIGN_IN_PATH}>
          Sign-in
        </Link>
      </Box>
      <Card>
        <CardBody>
          <Stack spacing={4}>
            <InputForward
              placeholder={t("placeholder.firstName")}
              errors={errors}
              type="firstName"
              {...register("firstName", { ...getRequiredValidator() })}
            />
            <InputForward
              placeholder={t("placeholder.lastName")}
              errors={errors}
              type="lastName"
              {...register("lastName", { ...getRequiredValidator() })}
            />
            <InputForward
              placeholder={t("placeholder.email")}
              errors={errors}
              type="email"
              {...register("email", { ...getEmailValidator() })}
            />

            <InputForward
              placeholder={t("placeholder.password")}
              errors={errors}
              type="password"
              {...register("password", { ...getRequiredValidator() })}
            />

            <Divider />

            <CheckboxForward
              errors={errors}
              label={
                <Trans t={t} i18nKey="signup.tc">
                  Yes, I agree with the
                  <ExternalLink onClick={onTCClick}>
                    Terms and Conditions
                  </ExternalLink>
                </Trans>
              }
              {...register("tc", { ...getRequiredValidator() })}
            />

            <CheckboxForward
              errors={errors}
              label={
                <Trans t={t} i18nKey="signup.privacy">
                  Yes, I agree with the
                  <ExternalLink onClick={onPrivacyClick}>
                    Privacy Declaration
                  </ExternalLink>
                </Trans>
              }
              {...register("privacy", { ...getRequiredValidator() })}
            />
            <CheckboxForward
              errors={errors}
              placeholder={t("signup.emailPermission")}
              {...register("informByEmail")}
            />

            <Button
              onClick={handleSubmit((data) => onSubmit(data))}
              disabled={!isValid}
              isLoading={isLoading}
            >
              {t("pageTitle.signUp")}
            </Button>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};
