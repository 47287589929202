import { AuthRedirect } from "auth/components/auth-redirect";
import { HomeView } from "user/views/home";
import { BookingAreas } from "./components/booking-flow/booking-areas";
import { BookingSlots } from "./components/booking-flow/booking-slots";
import { BookingLocations } from "./components/booking-flow/booking-locations";
import { BookRedirect } from "./components/booking-flow/booking-redirect";
import { BookView } from "./views/booking";
import { ProfileView } from "./views/profile";
import { SupportView } from "./views/support";
import { BookingDetailsView } from "./views/booking-details";
import { BookingReview } from "./components/booking-review";

export const HOME_PATH = "/";
export const SUPPORT_PATH = "/support";
export const BOOKING_PATH = "/booking";
export const BOOK_PATH = "/book";
export const BOOKING_AREAS_PATH = "areas";
export const BOOKING_LOCATION_PATH = "locations";
export const BOOKING_SLOTS_PATH = "slots";
export const BOOKING_DETAILS_PATH = "details";
export const BOOKING_REVIEW_PATH = "review";
export const BOOKING_UPCOMING_PATH = BOOKING_PATH + "/:id";
export const PROFILE_PATH = "/profile";

export const AREAS_PATH = "areas";
export const AREA_PATH = AREAS_PATH + "/:id";

export const LOCATIONS_PATH = "locations";
export const LOCATION_PATH = LOCATIONS_PATH + "/:id";

export const getBookPath = (path: string) => `${BOOK_PATH}/${path}`;
export const getBookingDetailsPath = (path: string) =>
  `${BOOKING_PATH}/${path}`;

export const userRoutes = [
  {
    path: HOME_PATH,
    label: "Home",
    element: (
      <AuthRedirect>
        <HomeView />
      </AuthRedirect>
    ),
  },
  {
    path: BOOK_PATH,
    label: "Book",
    element: (
      <AuthRedirect>
        <BookView />
      </AuthRedirect>
    ),
    children: [
      {
        path: "",
        label: "Book Redirect",
        element: (
          <AuthRedirect>
            <BookRedirect />
          </AuthRedirect>
        ),
      },
      {
        path: AREAS_PATH,
        label: "Areas",
        element: (
          <AuthRedirect>
            <BookingAreas />
          </AuthRedirect>
        ),
      },
      {
        path: AREA_PATH,
        label: "Area",
        element: (
          <AuthRedirect>
            <BookingLocations />
          </AuthRedirect>
        ),
      },
      {
        path: LOCATION_PATH,
        label: "Location",
        element: (
          <AuthRedirect>
            <BookingSlots />
          </AuthRedirect>
        ),
      },
      {
        path: BOOKING_REVIEW_PATH,
        label: "Review",
        element: (
          <AuthRedirect>
            <BookingReview />
          </AuthRedirect>
        ),
      },
    ],
  },

  {
    path: SUPPORT_PATH,
    label: "Support",
    element: (
      <AuthRedirect>
        <SupportView />
      </AuthRedirect>
    ),
  },
  {
    path: BOOKING_UPCOMING_PATH,
    element: <BookingDetailsView />,
  },
  {
    path: PROFILE_PATH,
    label: "Profile",
    element: (
      <AuthRedirect withAuth>
        <ProfileView />
      </AuthRedirect>
    ),
  },
];
