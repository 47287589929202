import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { Purple100 } from "design-system/colors";
import { useTranslation } from "react-i18next";

const Divider = (
  <Box my={5} border="none" color={Purple100}>
    〜
  </Box>
);

export const CheckInEn = () => {
  const { t } = useTranslation("common");
  return (
    <Stack spacing={5} textAlign="center" divider={Divider}>
      <Stack>
        <Heading size="sm">{t("checkin_prepare")}</Heading>
        <Text>{t("checkin_welcome")}</Text>
      </Stack>
      <Stack>
        <Heading size="sm">{t("checkin_locate_title")}</Heading>
        <Text>{t("checkin_locate_content")}</Text>
      </Stack>
      <Stack>
        <Heading size="sm">{t("checkin_gear_title")}</Heading>
        <Text>{t("checkin_gear_content")}</Text>
      </Stack>
      <Stack>
        <Heading size="sm">{t("checkin_checkout_title")}</Heading>
        <Text>{t("checkin_checkout_content")}</Text>
      </Stack>
    </Stack>
  );
};
