import { darken, lighten } from "polished";

export const Gray05 = "#f2f2f2";
export const Gray10 = "#f9f9f9";
export const Gray15 = "#99a4b2";

export const Black100 = "#040505";

export const Blue100 = "#1180C4";

export const Purple100 = "#7A6BFF";
export const PurpleLight02 = lighten(0.02, Purple100);
export const PurpleLight05 = lighten(0.05, Purple100);
export const PurpleLight07 = lighten(0.07, Purple100);
export const PurpleLight10 = lighten(0.1, Purple100);
export const PurpleDark02 = darken(0.02, Purple100);
export const PurpleDark05 = darken(0.05, Purple100);
export const PurpleDark07 = darken(0.07, Purple100);
export const PurpleDark10 = darken(0.1, Purple100);
export const PurpleDark15 = darken(0.15, Purple100);

export const Purple20 = lighten(0.2, Purple100);
export const Purple10 = lighten(0.25, Purple100);

export const Green100 = "#25d9c7";
export const GreenDark02 = darken(0.02, Green100);
export const GreenDark05 = darken(0.05, Green100);
export const GreenDark10 = darken(0.1, Green100);
export const GreenDark15 = darken(0.15, Green100);

export const Green50 = lighten(0.15, Green100);

export const Orange100 = "#ff9800";
export const OrangeLight02 = lighten(0.02, Orange100);
export const OrangeLight15 = lighten(0.15, Orange100);
export const OrangeLight20 = lighten(0.2, Orange100);
