import { Global } from "@emotion/react";

export const Fonts = () => (
  <Global
    styles={`
    @font-face {
      font-family: Avenir;
      src: local("Avenir"),url("/avenir.otf") format("opentype")
  }
      `}
  />
);
