import { Box, Card, CardBody, HStack, Spinner, Stack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatInTimeZone } from "date-fns-tz";
import { fetchBooking, FETCH_BOOKING_QUERY } from "user/endpoints";
import { TIMEZONE } from "user/utils";
import { convertNumberToTime } from "common/utils";
import { Button } from "design-system/button";
import { useAuth } from "auth/hooks/use-auth";
import { HOME_PATH } from "user/routes";
import { useSetRecoilState } from "recoil";
import { modalState } from "common/atoms";
import { CheckInModal } from "common/components/modals/check-in";
import { Calendar, Lock, MapPin } from "phosphor-react";

export const useBookingDetails = (id: string | undefined) => {
  const {
    data: booking,
    isLoading,
    isError,
  } = useQuery([FETCH_BOOKING_QUERY, id], () => {
    if (id) {
      return fetchBooking(id);
    }

    return null;
  });

  return { booking, isLoading, isError };
};

export const BookingDetails: React.FC = () => {
  const { id } = useParams();
  const setModal = useSetRecoilState(modalState);
  const { t } = useTranslation("common");
  const { isLoggedIn } = useAuth();

  const { booking, isLoading, isError } = useBookingDetails(id);

  if (isLoading) {
    return <Spinner size="md" />;
  }

  if (isError) {
    return <>Not found</>;
  }

  if (booking.status !== "PAID") {
    return <Navigate to={HOME_PATH} />;
  }

  const isManual = booking.lockers?.[0]?.station?.type === "MANUAL";

  return (
    <Stack spacing={4}>
      <Card
        sx={{
          boxShadow: "4px 4px 1px rgba(0,0,0,0.2), 0px -4px 0px #a9a1fd inset",
        }}
      >
        <CardBody sx={{ fontSize: 16 }}>
          <Stack spacing={3} sx={{ minWidth: { md: 340 } }}>
            <Stack direction="row" alignItems="center">
              <MapPin /> <strong>{t("bookingDetails.location")}</strong>
            </Stack>

            <div>
              <HStack>
                <div>{booking.location.name}</div>
              </HStack>
              <HStack>
                <div>
                  {booking.location.street} {booking.location.postcode}
                </div>
              </HStack>
            </div>
          </Stack>
        </CardBody>
      </Card>

      <Card
        sx={{
          boxShadow: "4px 4px 1px rgba(0,0,0,0.2), 0px -4px 0px #a9a1fd inset",
        }}
      >
        <CardBody sx={{ fontSize: 16 }}>
          <Stack spacing={1} direction="row" sx={{ minWidth: { md: 340 } }}>
            <Stack direction="row" alignItems="center">
              <Lock /> <strong>{t("bookingDetails.lockers")}:</strong>
            </Stack>
            <div>
              {(booking.lockers || [])
                .map((locker: any) => locker.address)
                .toString()}
            </div>
          </Stack>
        </CardBody>
      </Card>

      <Card
        sx={{
          boxShadow: "4px 4px 1px rgba(0,0,0,0.2), 0px -4px 0px #a9a1fd inset",
        }}
      >
        <CardBody sx={{ fontSize: 16 }}>
          <Stack spacing={3} sx={{ minWidth: { md: 340 } }}>
            <Stack direction="row" alignItems="center">
              <Calendar /> <strong>{t("bookingDetails.date")}</strong>
            </Stack>

            <div>
              <HStack
                divider={
                  <Box mx={2} border="none">
                    |
                  </Box>
                }
              >
                <div>
                  {formatInTimeZone(booking.dateString, TIMEZONE, "dd MMM")}
                </div>
                <div>{convertNumberToTime(booking.startHour)}</div>
                <div>
                  {booking.endHour - booking.startHour}
                  {t("bookingDetails.durationSuffix")}
                </div>
              </HStack>
            </div>
          </Stack>
        </CardBody>
      </Card>

      {isLoggedIn && !booking.isPastBooking && (
        <Card
          sx={{
            boxShadow:
              "4px 4px 1px rgba(0,0,0,0.2), 0px -4px 0px #a9a1fd inset",
          }}
        >
          <CardBody>
            <Stack>
              <Button
                onClick={() =>
                  setModal({
                    key: "CHECKIN",
                    component: CheckInModal,
                    props: { booking },
                  })
                }
              >
                {booking.isCheckedIn
                  ? isManual
                    ? t("show_boards")
                    : t("open_lockers")
                  : "Check-in"}
              </Button>
            </Stack>
          </CardBody>
        </Card>
      )}
    </Stack>
  );
};
