import { Box, Stack } from "@chakra-ui/react";
import { Button } from "design-system/button";
import { PageTitle } from "design-system/titles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const HomeWithoutAuth: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const onClick = () => {
    navigate("/book");
  };

  return (
    <Stack>
      <PageTitle>
        <Stack spacing={1}>
          <div>👋</div>
          <div>{t("welcome")}</div>
        </Stack>
      </PageTitle>
      <Stack
        spacing={8}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        w="full"
      >
        <Box maxW={400}>{t("welcome_intro")}</Box>
        <Box>{t("welcome_intro_2")}:</Box>
        <Box>{t("welcome_intro_3")}</Box>
        <Box>{t("welcome_intro_4")}</Box>
        <Button onClick={onClick}>{t("welcome_cta")}</Button>
      </Stack>
    </Stack>
  );
};
