import {
  Card,
  LinkBox,
  CardBody,
  CardProps,
  LinkOverlay,
  Stack,
} from "@chakra-ui/react";
import { Purple100 } from "design-system/colors";
import { CaretRight } from "phosphor-react";

interface Props extends CardProps {
  onClick: () => void;
  disabled?: boolean;
}

export const ClickableCard: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  onClick,
  disabled,
  ...props
}) => {
  return (
    <Card
      as={LinkBox}
      onClick={(ev) => {
        ev.preventDefault();

        if (!disabled) {
          onClick();
        }
      }}
      {...props}
      sx={{
        borderRadius: 4,
        boxShadow: "4px 4px 1px rgba(0,0,0,0.2), 0px -4px 0px #a9a1fd inset",
        opacity: disabled ? 0.3 : 1,
        ":active": {
          transform: "scale(0.99)",
        },
        ...props.sx,
      }}
    >
      <CardBody sx={{ color: Purple100, fontSize: 16 }}>
        <LinkOverlay href="#">
          <Stack direction="row" justifyContent="space-between">
            <div>{children}</div>
            <Stack height="full" alignSelf="center">
              <CaretRight size={24} />
            </Stack>
          </Stack>
        </LinkOverlay>
      </CardBody>
    </Card>
  );
};
