import { Route } from "common/types";
import { AuthRedirect } from "./components/auth-redirect";
import { ForgotPasswordView } from "./views/forgot-password";
import { ResetPasswordView } from "./views/reset-password";
import { SignInView } from "./views/sign-in";
import { SignUpView } from "./views/sign-up";

export const SIGN_IN_PATH = "/sign-in";
export const SIGN_UP_PATH = "/sign-up";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/reset-password";

export const authRoutes: Route[] = [
  {
    path: SIGN_IN_PATH,
    hideFromMenu: true,
    element: (
      <AuthRedirect withoutAuth>
        <SignInView />
      </AuthRedirect>
    ),
  },
  {
    path: SIGN_UP_PATH,
    hideFromMenu: true,
    element: (
      <AuthRedirect withoutAuth>
        <SignUpView />
      </AuthRedirect>
    ),
  },
  {
    path: FORGOT_PASSWORD_PATH,
    hideFromMenu: true,
    element: (
      <AuthRedirect withoutAuth>
        <ForgotPasswordView />
      </AuthRedirect>
    ),
  },
  {
    path: RESET_PASSWORD_PATH,
    hideFromMenu: true,
    element: (
      <AuthRedirect withoutAuth>
        <ResetPasswordView />
      </AuthRedirect>
    ),
  },
];
