import {
  getFromStorage,
  removeFromStorage,
  setInStorage,
  TOKEN_STORAGE,
} from "common/utils";
import { atom } from "recoil";
import { Profile } from "user/types";

export type SupProfile = Profile | null | undefined;

export const tokenState = atom<string | null>({
  key: "tokenState",
  default: getFromStorage(TOKEN_STORAGE),
  effects: [
    ({ onSet, setSelf }) => {
      onSet((token) => {
        if (token) {
          setInStorage(TOKEN_STORAGE, token);
        } else {
          removeFromStorage(TOKEN_STORAGE);
        }
        setSelf(token);
      });
    },
  ],
});
