import { Stack } from "@chakra-ui/react";
import { ProfileForm } from "auth/forms/profile";
import { PageTitle } from "design-system/titles";
import { useTranslation } from "react-i18next";

export const ProfileView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");

  return (
    <Stack>
      <PageTitle>{t("pageTitle.profile")}</PageTitle>
      <Stack spacing={4}>
        <ProfileForm />
      </Stack>
    </Stack>
  );
};
