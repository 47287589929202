import { Card, CardBody, HStack, Input, Stack } from "@chakra-ui/react";
import { useAuth } from "auth/hooks/use-auth";
import { SIGN_UP_PATH } from "auth/routes";
import { addBooking, FETCH_SLOTS_QUERY } from "common/endpoints";
import { useSnack } from "common/hooks/use-snack";
import { bookingConverter, convertNumberToTime } from "common/utils";
import { formatInTimeZone } from "date-fns-tz";
import { Button, UnstyledButton } from "design-system/button";
import { SLink } from "design-system/s-link";
import { TitleMedium } from "design-system/titles";
import { ArrowCircleLeft, Pencil } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "react-query";
import { Navigate, useNavigate } from "react-router-dom";
import { useBookingFlow } from "user/hooks/use-booking-flow";
import { BOOKING_LOCATION_PATH, LOCATIONS_PATH } from "user/routes";
import { AREAS_PATH } from "user/routes";
import { getBookPath, BOOK_PATH } from "user/routes";
import { Profile } from "user/types";
import { TIMEZONE } from "user/utils";
interface Props {
  isManual?: boolean;
}

export const BookingReview: React.FC<React.PropsWithChildren<Props>> = () => {
  const { t } = useTranslation("common");
  const { successMessage } = useSnack();
  const queryClient = useQueryClient();
  const { isLoggedIn, profile } = useAuth();
  const navigate = useNavigate();
  const { booking, isComplete, setBooking, getLocationFromUid } =
    useBookingFlow();
  const { mutate: book, isLoading: isBookLoading } = useMutation(
    (data: Partial<any> & { country: string | undefined }) => {
      return addBooking(data);
    },
    {
      onSuccess: (response) => {
        successMessage(t("bookingFlow.confirmed"));

        if (response.url) {
          window.location.href = response.url;
        }
      },
      onError: () => {
        queryClient.invalidateQueries(FETCH_SLOTS_QUERY);
      },
    }
  );

  if (!isComplete) {
    return <Navigate to={BOOK_PATH} />;
  }

  return (
    <Stack spacing={4}>
      <HStack spacing={2}>
        <SLink
          to={getBookPath(`${BOOKING_LOCATION_PATH}/${booking.locationId}`)}
        >
          <ArrowCircleLeft size={24} />
        </SLink>
        <TitleMedium>{t("bookingFlow.review")}</TitleMedium>
      </HStack>
      <Card>
        <CardBody>
          <Stack spacing={4} sx={{ minWidth: { md: 340 } }}>
            {!!booking?.locationId && (
              <HStack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <HStack>
                  <strong>{t("bookingFlow.review.location")}</strong>
                  <div>{getLocationFromUid(booking.locationId)?.name}</div>
                </HStack>
                <UnstyledButton
                  onClick={() => {
                    if (booking.locationId) {
                      navigate(
                        getBookPath(
                          `${AREAS_PATH}/${getLocationFromUid(
                            booking.locationId
                          )?.areaId}`
                        )
                      );
                    }
                  }}
                >
                  <Pencil size={24} color="black" />
                </UnstyledButton>
              </HStack>
            )}
            {!!booking?.date && !!booking?.locationId && (
              <HStack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <HStack>
                  <strong>{t("bookingFlow.review.date")}</strong>
                  <div>
                    {formatInTimeZone(booking.date, TIMEZONE, "dd MMM")}
                  </div>
                </HStack>
                <UnstyledButton
                  onClick={() => {
                    navigate(
                      getBookPath(`${LOCATIONS_PATH}/${booking.locationId}`)
                    );
                  }}
                >
                  <Pencil size={24} color="black" />
                </UnstyledButton>
              </HStack>
            )}
            {!!booking?.boardsNumber && !!booking?.locationId && (
              <HStack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <HStack>
                  <strong>{t("bookingFlow.review.boardsNr")}</strong>
                  <div>{booking.boardsNumber}</div>
                </HStack>
                <UnstyledButton
                  onClick={() => {
                    navigate(
                      getBookPath(`${LOCATIONS_PATH}/${booking.locationId}`)
                    );
                  }}
                >
                  <Pencil size={24} color="black" />
                </UnstyledButton>
              </HStack>
            )}
            {!!booking?.duration && !!booking?.locationId && (
              <HStack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <HStack>
                  <strong>{t("bookingFlow.duration")}</strong>
                  <div>{booking.duration}h</div>
                </HStack>
                <UnstyledButton
                  onClick={() => {
                    navigate(
                      getBookPath(`${LOCATIONS_PATH}/${booking.locationId}`)
                    );
                  }}
                >
                  <Pencil size={24} color="black" />
                </UnstyledButton>
              </HStack>
            )}
            {!!booking?.time && !!booking?.locationId && (
              <HStack
                justifyContent="space-between"
                alignItems="center"
                direction="row"
              >
                <HStack>
                  <strong>{t("bookingFlow.review.time")}</strong>
                  <div>{convertNumberToTime(booking.time)}</div>
                </HStack>
                <UnstyledButton
                  onClick={() => {
                    navigate(
                      getBookPath(`${LOCATIONS_PATH}/${booking.locationId}`)
                    );
                  }}
                >
                  <Pencil size={24} color="black" />
                </UnstyledButton>
              </HStack>
            )}
            <div>
              <Input
                type="text"
                name="discount"
                value={booking.discount}
                onChange={(ev) => setBooking({ discount: ev.target.value })}
                placeholder={t("placeholder.discount_code")}
              />
              <HStack spacing={2} mt={3} justify="end">
                <Button
                  isLoading={isBookLoading}
                  onClick={() => {
                    if (isLoggedIn) {
                      const profId = (profile as Profile).id;
                      book({
                        ...bookingConverter(booking, profId),
                        country: profile?.country,
                      });
                    } else {
                      navigate(SIGN_UP_PATH);
                    }
                  }}
                >
                  {isLoggedIn
                    ? t("bookingFlow.review.bookNow")
                    : t("bookingFlow.review.signUpToBook")}
                </Button>
              </HStack>
            </div>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};
