import { Stack } from "@chakra-ui/react";
import { ExternalLink } from "design-system/links";
import { PageTitle } from "design-system/titles";
import { At, Phone } from "phosphor-react";
import { useTranslation } from "react-i18next";

export const SupportView: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");

  return (
    <Stack>
      <PageTitle>{t("pageTitle.support")}</PageTitle>
      <Stack spacing={1}>
        <Stack spacing={1} direction="row" alignItems="center">
          <At />
          <ExternalLink href="mailto:info@supsupclub.com">
            info@supsupclub.com
          </ExternalLink>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center">
          <Phone />
          <ExternalLink href="tel:+31851309686">+31851309686</ExternalLink>
        </Stack>
      </Stack>
    </Stack>
  );
};
