import { Navigate } from "react-router-dom";
import { useBookingFlow } from "user/hooks/use-booking-flow";
import {
  BOOKING_AREAS_PATH,
  BOOKING_REVIEW_PATH,
  BOOKING_LOCATION_PATH,
} from "user/routes";

export const BookRedirect: React.FC<React.PropsWithChildren> = () => {
  const { booking, isComplete } = useBookingFlow();

  if (isComplete) {
    return <Navigate to={BOOKING_REVIEW_PATH} replace />;
  }

  if (!booking?.areaId) {
    return <Navigate to={BOOKING_AREAS_PATH} replace />;
  }

  if (!booking?.locationId) {
    return <Navigate to={`${BOOKING_AREAS_PATH}/${booking.areaId}`} replace />;
  }

  return (
    <Navigate to={`${BOOKING_LOCATION_PATH}/${booking.locationId}`} replace />
  );
};
