import styled from "@emotion/styled";

export const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.section`
  overflow: auto;
  flex: 1;
  padding: 0 24px 24px;
`;
