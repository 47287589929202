import { Spinner, Stack } from "@chakra-ui/react";
import { ClickableCard } from "common/components/clickable-card";
import {
  FETCH_LOCATION_BY_ID_QUERY,
  FETCH_SLOTS_QUERY,
  fetchLocationById,
  getAvailableSlots,
} from "common/endpoints";
import { convertNumberToTime } from "common/utils";
import { TitleMedium } from "design-system/titles";
import { ArrowCircleLeft, Circle, Clock } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useBookingFlow } from "user/hooks/use-booking-flow";
import { AREAS_PATH, BOOKING_REVIEW_PATH, getBookPath } from "user/routes";
import { BookingDetails } from "../booking-details";
import { useEffect } from "react";
import { UnstyledButton } from "design-system/button";
interface Props {
  isManual?: boolean;
  nextCallback?: () => void;
  backCallback?: () => void;
}

let scrolledOnce = false;
let firstVisit = true;

export const BookingSlots: React.FC<React.PropsWithChildren<Props>> = () => {
  const { t } = useTranslation("common");
  const { id: locationId } = useParams();
  const navigate = useNavigate();

  const { data: location, isLoading: loadingLocation } = useQuery(
    [FETCH_LOCATION_BY_ID_QUERY, locationId],
    async () => {
      if (locationId) {
        return fetchLocationById(locationId);
      }

      return null;
    }
  );

  const { booking, setBooking } = useBookingFlow();
  const { data: slots, isLoading: loadingSlots } = useQuery(
    [FETCH_SLOTS_QUERY, booking?.date, booking.duration, locationId],
    () => {
      if (
        booking?.date &&
        locationId &&
        booking.duration &&
        booking.boardsNumber
      ) {
        return getAvailableSlots(
          locationId,
          booking.date,
          booking.duration,
          booking.boardsNumber
        );
      }

      return null;
    },
    {
      staleTime: 0,
      onSuccess: (data) => {
        const indexAvailable = (data || []).findIndex(
          (slot) => slot.availability
        );

        if (indexAvailable >= 0 && !scrolledOnce && !firstVisit) {
          setTimeout(() => {
            const element = document.querySelector(".slot-available");
            const scroller = document.getElementById("scroller");

            if (element && scroller) {
              const scrollerRect = scroller.getBoundingClientRect();
              const rect = element.getBoundingClientRect();
              const offset = 100;
              const y =
                rect.top - scrollerRect.top + scroller.scrollTop - offset;

              scroller.scrollTo({
                top: y,
                behavior: "smooth",
              });
              scrolledOnce = true;
            }
          }, 100);
        }
        firstVisit = false;
      },
    }
  );

  useEffect(() => {
    if (location) {
      if (booking?.locationId !== location.id) {
        setBooking({ locationId: location.id });
      }

      if (booking?.areaId !== location.areaId) {
        setBooking({ areaId: location.areaId });
      }
    }
  }, [location, setBooking, booking?.locationId, booking.areaId]);

  if (loadingLocation) {
    return <Spinner />;
  }

  return (
    <Stack spacing={4}>
      <Stack direction="row" alignItems="start" spacing={2}>
        <UnstyledButton
          onClick={() => {
            navigate(getBookPath(`${AREAS_PATH}/${location?.areaId}`));
          }}
        >
          <ArrowCircleLeft style={{ marginTop: 2 }} size={24} />
        </UnstyledButton>
        <Stack spacing={2}>
          <div>
            <TitleMedium>{location?.name}</TitleMedium>
            <small>
              {location?.street} {location?.postcode}
            </small>
          </div>
        </Stack>
      </Stack>
      <BookingDetails />
      {loadingSlots ? (
        <Stack alignItems="center" justifyContent="center">
          <Spinner color="white" sx={{ mt: 4 }} />
        </Stack>
      ) : slots && Boolean(slots?.length) ? (
        <Stack spacing={4}>
          {slots.map((slot, slotIndex) => {
            const isDisabled =
              !slot.availability ||
              +(booking?.boardsNumber || 0) > slot.availability;
            return (
              <ClickableCard
                className={!isDisabled ? `slot-available` : undefined}
                key={`slot-${slotIndex}`}
                disabled={isDisabled}
                onClick={() => {
                  setBooking({
                    time: slot.time,
                  });

                  navigate(getBookPath(BOOKING_REVIEW_PATH));
                }}
              >
                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Clock />
                    <div>{convertNumberToTime(slot.time)}</div>
                  </Stack>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Circle
                      size={16}
                      weight="fill"
                      color={slot.availability > 3 ? "#3cde55" : "#ffb100"}
                    />
                    <div>
                      {t("bookingFlow.slotsList.spotsAvailable", {
                        count: slot.availability,
                      })}
                    </div>
                  </Stack>
                </Stack>
              </ClickableCard>
            );
          })}
        </Stack>
      ) : (
        <Stack>{!!booking.date && <div>No slots available</div>}</Stack>
      )}
    </Stack>
  );
};
