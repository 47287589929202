import axios, { AxiosError } from "axios";
import {
  TOKEN_STORAGE,
  getToken,
  removeFromStorage,
  toast,
} from "common/utils";
import { t } from "i18next";

const domain = window.location.href.includes("localhost")
  ? "http://localhost:8085"
  : "https://supsup-api.ew.r.appspot.com";

const supxios = axios.create({
  baseURL: `${domain}`,
  timeout: 10000,
  headers: {
    "X-Client-Origin": window.location.origin,
  },
});

supxios.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

supxios.interceptors.response.use(
  (response) => response,
  (error: AxiosError<{ message?: string }>) => {
    const errorKey =
      error.response?.data?.message || error.message || "generalError";

    toast({
      description: t(`error.${errorKey}`, { ns: "common" }),
      status: "error",
      duration: 3000,
      position: "top-right",
    });

    if (errorKey.includes("jwt")) {
      removeFromStorage(TOKEN_STORAGE);
      window.location.href = "/";
    }

    throw error;
  }
);

export { supxios };
