import { Navigate, useLocation } from "react-router-dom";
import { BOOK_PATH, PROFILE_PATH } from "user/routes";
import { useShouldCompleteProfile } from "user/utils";
import { useAuth } from "auth/hooks/use-auth";
import { useBookingFlow } from "user/hooks/use-booking-flow";

interface Props extends React.PropsWithChildren {
  withAuth?: boolean;
  withoutAuth?: boolean;
}

export const AuthRedirect: React.FC<Props> = ({
  withAuth,
  withoutAuth,
  children,
}) => {
  const { isLoggedIn } = useAuth();
  const isProfileIncomplete = useShouldCompleteProfile();
  const { isComplete } = useBookingFlow();
  const location = useLocation();

  if (isProfileIncomplete && location.pathname !== PROFILE_PATH) {
    return <Navigate to={PROFILE_PATH} />;
  }

  if (withAuth) {
    return !isLoggedIn ? <Navigate to="/" /> : <>{children}</>;
  }

  if (withoutAuth) {
    return isLoggedIn ? (
      <Navigate to={isComplete ? BOOK_PATH : "/"} />
    ) : (
      <>{children}</>
    );
  }

  return <>{children}</>;
};
