import { Privacy } from "./components/modals/privacy";
import { TC } from "./components/modals/tc";
import { Route } from "./types";
import { NotFound } from "./views/not-found";

export const NOT_FOUND_PATH = "*";
export const TERMS_PATH = "/terms";
export const PRIVACY_PATH = "/privacy";

export const commonRoutes: Route[] = [
  {
    path: TERMS_PATH,
    hideFromMenu: true,
    element: <TC />,
  },
  {
    path: PRIVACY_PATH,
    hideFromMenu: true,
    element: <Privacy />,
  },
  {
    path: NOT_FOUND_PATH,
    hideFromMenu: true,
    element: <NotFound />,
  },
];
