import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

export const Link = styled(NavLink)`
  text-decoration: underline;
`;

export const ExternalLink = styled.a`
  text-decoration: underline;
`;

export const ButtonLink = styled.button``;
