import { t } from "i18next";

export const getRequiredValidator = (minValue = 3) => ({
  required: t("field_is_required", { ns: "common" }),
  minLength: {
    value: minValue,
    message: t("minimum_length_3", { ns: "common" }),
  },
});

export const getDateStringValidator = () => ({
  pattern: {
    value: /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[012])-[0-9]{4}$/,
    message: t("invalid_format_date", { ns: "common" }),
  },
  required: t("field_is_required", { ns: "common" }),
  length: 9,
});

export const getPhoneNumberValidator = () => ({
  pattern: {
    value: /^(?:\+)?\d+$/,
    message: t("invalid_format", { ns: "common" }),
  },
  required: t("field_is_required", { ns: "common" }),
});

export const getEmailValidator = () => ({
  pattern: {
    value: /\S+@\S+\.\S+/,
    message: t("invalid_format", { ns: "common" }),
  },
  ...getRequiredValidator(),
});
