import { Link } from "design-system/links";
import { PageTitle } from "design-system/titles";

export const NotFound: React.FC<React.PropsWithChildren> = () => {
  return (
    <>
      <PageTitle>Page not found</PageTitle>
      <p>
        We were not able to find your page, please{" "}
        <Link to="/">return home</Link>
      </p>
    </>
  );
};
