import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { modalState } from "common/atoms";
import { useRecoilState } from "recoil";

const empty = {};

export const Modals: React.FC<React.PropsWithChildren> = () => {
  const [modal, setModal] = useRecoilState(modalState);

  const handleClose = () => {
    setModal({
      ...modal,
      key: null,
    });
  };

  const modalProps = {
    handleClose,
    ...(modal?.props ? modal.props : empty),
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={!!modal?.key}
      scrollBehavior="inside"
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {modal.component && <modal.component {...modalProps} />}
      </ModalContent>
    </Modal>
  );
};
