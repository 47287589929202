import {
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Stack,
} from "@chakra-ui/react";

export const TCEN = () => {
  return (
    <div>
      <Heading as="h1" size="md" mt={4}>
        General Terms and Conditions of Sup Sup Club B.V.
      </Heading>
      <Stack spacing={4} mt={4}>
        <Heading size="md">Article 1 - Definitions</Heading>
        <Text>
          Participant: Anyone who enters into an agreement with Sup Sup Club,
          including those who use a sup rented by the Participant, and/or if the
          Participant rents several sups for others, these other users. Sup Sup
          Club: Sup Sup Club B.V., the private limited liability Sup Sup, with
          its registered office in Amsterdam and place of business at Zandhoek
          22, (1013KT) Amsterdam, registered with the Amsterdam Chamber of
          Commerce under number 81205309. Participant Agreement: The agreement
          concluded between the Participant and Sup Sup Club via the website,
          whereby these General Terms and Conditions are accepted, or when the
          Participant has made use of Sup Sup Club&apos;s facilities. Personal
          data: All data provided by the Participant to Sup Sup Club as shown in
          the Participant Agreement.
        </Text>
        <Heading size="md">Article 2: Applicability</Heading>
        <Text>
          2.1 These General Terms and Conditions apply to all legal
          relationships between Sup Sup Club and each Participant. The
          Participant&apos;s General Terms and Conditions do not apply. 2.2 All
          clauses in these General Terms and Conditions have also been drafted
          for the benefit of all Sup Sup Club directors and all persons working
          for and/or engaged by Sup Sup Club. 2.3 Deviations from these General
          Terms and Conditions are only valid if expressly agreed upon in
          writing.
        </Text>
        <Heading size="md">Article 3 Participation Agreement</Heading>
        <Text>
          3.1 The Participant Agreement between the Participant and the Sup Sup
          Club is concluded at the moment that (a) the Participant has provided
          all requested information as shown in the registration form on the
          website, has accepted the General Terms and Conditions, and has made
          payment; or (b) the Participant has made use of the Sup Sup
          Club&apos;s facilities. If casual rental (losse verhuur) is involved,
          the Participant Agreement shall end automatically after the rental
          period. 3.2 The General Terms and Conditions and the Participant
          Agreement together constitute the complete representation of the
          rights and obligations of the Sup Sup Club and the Participant, and
          replace all previous written and oral announcements, agreements, and
          other correspondence. 3.3 The Participant must ensure that all
          information, which the Sup Sup Club deems desirable or which the
          Participant should reasonably understand to be necessary for the
          execution of the Participation Agreement, is provided to the Sup Sup
          Club in a timely manner. 3.4 The Participant Agreement is personal,
          and the Participant is not entitled to transfer the rights and
          obligations arising from the Participant Agreement concluded under
          these General Terms and Conditions, in whole or in part, to third
          parties. 3.5 If the Participant decides to purchase a new or
          additional product from the Sup Club, the terms of the Participant
          Agreement and these General Terms and Conditions shall continue to
          apply in full unless expressly agreed otherwise in writing. 3.6 Sup
          Sup Club is entitled to unilaterally amend these General Terms and
          Conditions. 3.7 The minimum age to independently rent a SUPboard, buy
          a strip card, or take out a subscription is 18+.
        </Text>
        <Heading size="md">Article 4 The Participant</Heading>
        <Text>The Participant meets the following requirements:</Text>
        <UnorderedList>
          <ListItem>
            The Participant(s) have a swimming diploma A (or its foreign
            equivalent); and
          </ListItem>
          <ListItem>The Participant(s) are fit and healthy.</ListItem>
        </UnorderedList>
        <Text>
          Sup Club always reserves the right to refuse Participant(s), without
          giving reasons.
        </Text>
        <Heading size="md">Article 5 Prices and payment</Heading>
        <Text>
          5.1 The fees for both casual rental (losse verhuur) of the Sup Sup
          Club&apos;s facilities must be paid to the Sup Sup Club before use.
          5.2 Sup Sup Club reserves the right to adjust and modify its prices
          and rates.
        </Text>
        <Heading size="md">
          Article 6 Registration and Cancellation of Rentals/Lessons
        </Heading>
        <Text>
          6.1 The Participant must register through the designated online
          registration system prior to renting a SUPboard or taking a lesson.
          Without registration, it is not possible to participate in a lesson or
          rent a SUPboard. 6.2 After a booking has been made, the Participant
          cannot cancel and incurred costs will not be refunded.
        </Text>
        <Heading size="md">Article 7 Risk and Liability</Heading>
        <Text>
          7.1 Sup Sup Club is only responsible for renting out high-quality
          SUPboards and paddles. Therefore, Sup Sup Club is liable only if there
          is something wrong with these SUPboards or paddles. Before going on
          the water, the Participant should check whether the equipment is free
          of defects. If there is something wrong, the Participant should
          contact Sup Sup Club before going on the water. Sup Sup Club will then
          provide new equipment as soon as possible. Sup Sup Club&apos;s
          liability for failure to fulfill its obligations under this Agreement
          is limited to the amount paid out by the insurer. This limitation does
          not apply if the damage is the result of gross negligence or intent
          (grove schuld of opzet) of one of Sup Sup Club&apos;s statutory
          directors. 7.2 The use of the Sup Sup Club&apos;s facilities for any
          activity is entirely at the Participant&apos;s own risk and expense.
          7.3 The Participant acknowledges that supping involves the risk of
          falling into (cold) water and possible risks to the Participant and
          their property. The Participant also acknowledges and is aware that
          when renting or using a SUPboard - in case of rental - there is no
          guidance from Sup Sup Club at the rental location. The Participant
          indemnifies (vrijwaart) Sup Sup Club against any liability for any
          form of damage or injury and expressly acknowledges the possible
          consequences of supping. 7.4 If the Participant is taking part in a
          lesson or event, they are obliged to report any injuries, illnesses,
          or physical ailments, as well as provide any information necessary for
          the performance of exercises to the trainer before, during, and after
          the training. The Participant is responsible for reporting this
          information. The Participant who registers also indemnifies
          (vrijwaart) Sup Sup Club against claims from fellow Participants. 7.5
          Neither Sup Sup Club nor its employees are liable for any material or
          immaterial damage resulting from an accident or injury to the
          Participant or third parties. 7.6 Sup Sup Club and its employees are
          not responsible for any damage, loss, or theft of property belonging
          to the Participant or third parties. 7.7 The Participant is liable for
          any damage caused to Sup Sup Club&apos;s property if the damage is a
          result of negligence or fault (nalatigheid en/of schuld) on the
          Participant&apos;s part. 7.8 The exclusions and limitations of
          liability set out in this Article also apply to and are stipulated for
          the benefit of Sup Sup Club&apos;s subordinates and any other persons
          whose assistance Sup Sup Club utilizes in the execution of the
          Participant&apos;s Agreement.
        </Text>
        <Heading size="md">Article 8 Personal data and privacy policy</Heading>
        <Text>
          8.1 The Participant is aware that he/she provides certain personal
          data to the Sup Sup Club, including name, age, address, and email
          address. 8.2 Any processing of personal data by the Sup Sup Club is
          done in accordance with the General Data Protection Regulation and its
          privacy policy, which can be found at www.supsupclub.com.
        </Text>
        <Heading size="md">
          Article 9 Applicable law and competent court
        </Heading>
        <Text>
          9.1 These General Terms and Conditions are effective from April 1st,
          2023. 9.2 In the event that any provision in these General Terms and
          Conditions is null and void or annulled, this shall not affect the
          validity of the other provisions. The Sup Club and Participant will
          then consult to agree on new provisions to replace the void or
          nullified provisions, taking into account the purpose and purport of
          the original provision as much as possible. 9.3 Every legal
          relationship with the Sup Sup Club is governed by Dutch law. All
          disputes between the Participant and Sup Sup Club that may arise as a
          result of or in connection with the Agreement shall be settled
          exclusively by the competent court in Amsterdam.
        </Text>
      </Stack>
    </div>
  );
};
