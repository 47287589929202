import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import { Banners } from "common/components/banners";
import { Router } from "common/components/router";
import { useLayoutUtils } from "common/hooks/use-breakpoints";
import { useEffect } from "react";
import { Header } from "user/components/header";
import { Menu } from "user/components/menu";
import { Body, Main } from "./styles";

export const Entry: React.FC<React.PropsWithChildren> = () => {
  const { innerHeight, setGlobalHeight } = useLayoutUtils();

  useEffect(() => {
    const onResize = () => setGlobalHeight(window.innerHeight);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return (
    <Grid templateColumns={{ base: "'repeat(1, 1fr)'", md: "repeat(2, 1fr)" }}>
      <GridItem>
        <Box
          display={{ base: "none", md: "block" }}
          height={{ base: "auto", md: innerHeight }}
          backgroundImage="url(/home.png)"
          backgroundPosition="center center"
          backgroundSize="cover"
        />
      </GridItem>
      <GridItem>
        <Main style={{ height: innerHeight }}>
          <Flex as="header" height={16}>
            <Header />
          </Flex>
          <Body id="scroller">
            <Banners />
            <Router />
          </Body>
          <Flex as="footer" height={20}>
            <Menu />
          </Flex>
        </Main>
      </GridItem>
    </Grid>
  );
};
