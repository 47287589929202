import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

const HeadingBase = styled(Box)`
  font-weight: 500;
  margin: 0;
  position: relative;
`;

export const PageTitle = styled(HeadingBase)`
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 12px;
  text-align: center;

  &:after {
    content: "〜";
    display: block;
  }
`;

export const TitleSmall = styled(HeadingBase)`
  font-size: 12px;
  text-transform: uppercase;
`;

export const TitleMedium = styled(HeadingBase)`
  font-size: 18px;
`;
