import { Badge, Spinner, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { fetchUpcomingBookings } from "common/endpoints";
import { useAuth } from "auth/hooks/use-auth";
import { ClickableCard } from "common/components/clickable-card";
import { Location } from "common/types";
import { convertNumberToTime } from "common/utils";
import { formatInTimeZone } from "date-fns-tz";
import { Text16 } from "design-system/text";
import { PageTitle, TitleMedium } from "design-system/titles";
import { Calendar, Hash, MapPinLine } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { FETCH_MY_BOOKINGS_QUERY } from "user/endpoints";
import { TIMEZONE } from "user/utils";
import { getBookingDetailsPath } from "user/routes";

export const getBGColor = (status: string) => {
  if (status === "PAID") return "#00ff8d";
  if (status === "PENDING") return "#ffdb76";
  return "#ff6969";
};

export const HomeWithAuth: React.FC<React.PropsWithChildren> = () => {
  const { profile } = useAuth();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const { data: myBookings, isFetching } = useQuery(
    [FETCH_MY_BOOKINGS_QUERY, profile?.id],
    () => {
      if (profile?.id) {
        return fetchUpcomingBookings();
      }

      return null;
    }
  );
  return (
    <Stack>
      <PageTitle>
        <Stack spacing={1}>
          <div>👋</div>
          <div>
            {t("welcome")} {profile?.firstName}
          </div>
        </Stack>
      </PageTitle>
      {isFetching ? (
        <Stack mt={2} alignItems="center" justifyContent="Center">
          <Spinner size="lg" color="secondary" />
        </Stack>
      ) : (
        <Stack spacing={2}>
          <TitleMedium>{t("home.yourBookings")}</TitleMedium>
          {!!myBookings?.length &&
            myBookings.map((booking: any) => {
              return (
                <ClickableCard
                  key={booking.id}
                  onClick={() => {
                    if (booking.status === "PENDING") {
                      window.location.href = booking.payByLink!;
                    }
                    if (booking.status === "PAID") {
                      navigate(getBookingDetailsPath(booking.id));
                    }
                  }}
                  sx={{ position: "relative" }}
                >
                  <Stack spacing={0}>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <MapPinLine />
                      <div>{(booking.location as Location)?.name}</div>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Calendar />
                      <div>
                        {formatInTimeZone(
                          booking.dateString,
                          TIMEZONE,
                          "dd MMM"
                        )}{" "}
                        @ {convertNumberToTime(booking.startHour)},{" "}
                        {booking.endHour - booking.startHour}h
                      </div>
                    </Stack>
                    <Stack spacing={1} direction="row" alignItems="center">
                      <Hash />
                      <div>
                        {t("booking.boardsNumber", {
                          count: booking.boards,
                        })}
                      </div>
                    </Stack>
                  </Stack>
                  <Badge
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 5,
                      fontSize: 10,
                      backgroundColor: getBGColor(booking.status as string),
                    }}
                  >
                    {t(`booking.${booking.status}`)}
                  </Badge>
                </ClickableCard>
              );
            })}
          {!myBookings?.length && (
            <Text16>{t("home.yourBookings.noResults")}</Text16>
          )}
        </Stack>
      )}
    </Stack>
  );
};
