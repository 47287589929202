import { getFromStorage, setInStorage } from "common/utils";
import { atom } from "recoil";

export const LOCALE_STORAGE = "locale";
export const defaultLocale = getFromStorage(LOCALE_STORAGE) || "en";

export const localeState = atom<string>({
  key: "localeState",
  default: defaultLocale,
  effects: [
    ({ onSet, setSelf }) => {
      onSet((locale) => {
        setInStorage(LOCALE_STORAGE, locale);
        setSelf(locale);
      });
    },
  ],
});
