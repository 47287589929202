import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputProps,
} from "@chakra-ui/react";
import React, { forwardRef } from "react";
import { FieldErrorsImpl } from "react-hook-form";

export interface InputForwardProps {
  errors: Partial<FieldErrorsImpl>;
  name: string;
  placeholder?: string;
  label?: string | React.ReactElement | null;
}

export const InputForward = forwardRef<any, InputForwardProps & InputProps>(
  ({ errors, ...props }, ref) => (
    <FormControl isInvalid={!!errors[props.name]}>
      <FormLabel htmlFor={props.name} mx={0}>
        {props.label || props.placeholder}
      </FormLabel>
      <Input ref={ref} {...props} />
      <FormErrorMessage>
        {errors[props.name] && <>{errors[props.name]?.message}</>}
      </FormErrorMessage>
    </FormControl>
  )
);

InputForward.displayName = "InputForward";
