import { atom } from "recoil";
import { Area, Location, ModalState } from "./types";

const initModal: ModalState = {
  key: null,
  component: null,
};

export const modalState = atom<ModalState>({
  key: "modalState",
  default: initModal,
});

export const locationsState = atom<Location[] | null>({
  key: "locationsState",
  default: null,
});

export const areasState = atom<Area[] | null>({
  key: "areasState",
  default: null,
});
