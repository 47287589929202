import { Spinner, Stack } from "@chakra-ui/react";
import { useAuth } from "auth/hooks/use-auth";
import { useLayoutUtils } from "common/hooks/use-breakpoints";

export const AuthProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { isProfileLoading } = useAuth();
  const { innerHeight } = useLayoutUtils();

  return isProfileLoading ? (
    <Stack height={innerHeight} alignItems="center" justifyContent="Center">
      <Spinner color="white" />
    </Stack>
  ) : (
    <>{children}</>
  );
};
