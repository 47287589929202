import { localeState } from "i18n/atoms";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import "i18n";

export const TranslationProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const locale = useRecoilValue(localeState);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale, i18n]);

  return <>{props.children}</>;
};
