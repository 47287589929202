import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { BrowserRouter } from "react-router-dom";
import { Theme } from "./common/components/theme";
import { QueryClientProvider, QueryClient } from "react-query";
import { TranslationProvider } from "i18n/components/translation-provider";
import { AuthProvider } from "auth/components/auth-provider";
import { Modals } from "common/components/modals";
import { SettingsDrawer } from "user/components/settings-drawer";
import { ColorModeScript } from "@chakra-ui/react";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <ColorModeScript initialColorMode="dark" />
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Theme>
            <AuthProvider>
              <TranslationProvider>
                <App />
                <Modals />
                <SettingsDrawer />
              </TranslationProvider>
            </AuthProvider>
          </Theme>
        </BrowserRouter>
      </QueryClientProvider>
    </RecoilRoot>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
