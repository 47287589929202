import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const UnstyledButton = styled.button`
  outline: none;
  background-color: transparent;
  color: inherit;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ChakraButton isDisabled={props.disabled} {...props}>
      {children}
    </ChakraButton>
  );
};
