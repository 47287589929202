import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  HStack,
  Heading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { ModalProps } from "common/types";
import { CheckInEn } from "./check-in-en";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { modalState } from "common/atoms";
import { useSnack } from "common/hooks/use-snack";
import { useMutation, useQueryClient } from "react-query";
import {
  FETCH_BOOKING_QUERY,
  checkInBooking,
  openLocker,
} from "user/endpoints";
import { Checkbox } from "design-system/checkbox";
import { useState } from "react";
import { useBookingDetails } from "user/components/booking-details";
import { Purple100 } from "design-system/colors";
import { ArrowDown, LockSimpleOpen } from "phosphor-react";

export const CheckInModal: React.FC<ModalProps> = ({ handleClose }) => {
  const { props } = useRecoilValue(modalState);
  const { booking }: any = useBookingDetails(props?.booking.id);
  const isCheckedIn = booking?.isCheckedIn;
  const { t } = useTranslation("common");
  const [hideScrollIndication, setHide] = useState(false);
  const isManual = booking?.lockers?.[0]?.station?.type === "MANUAL";

  return (
    <>
      <ModalHeader></ModalHeader>
      <ModalBody
        p={2}
        onScroll={(ev) => {
          const el = ev.target as HTMLDivElement;
          const scrollTop = el.scrollTop;
          const scrollHeight = el.scrollHeight;
          const clientHeight = el.clientHeight;

          setHide(scrollTop + clientHeight >= scrollHeight - 10);
        }}
      >
        {!isCheckedIn && <CheckInEn />}
        <Box mt={2}>
          {isCheckedIn ? (
            <Stack spacing={4}>
              <Stack direction="column" spacing={1}>
                <Alert status="warning" rounded="md" fontSize="sm" py={2}>
                  <AlertIcon />
                  <AlertTitle>{t("alert.alwaysCloseLockers")}</AlertTitle>
                </Alert>
                <Alert status="warning" rounded="md" fontSize="sm" py={2}>
                  <AlertIcon />
                  <AlertTitle>{t("alert.returnLate")}</AlertTitle>
                </Alert>
                <Alert status="info" rounded="md" fontSize="sm" py={2}>
                  <AlertIcon />
                  <AlertTitle>
                    {t(isManual ? "alert.askForBoards" : "alert.unlockLockers")}
                  </AlertTitle>
                </Alert>
              </Stack>

              <SimpleGrid templateColumns="1fr">
                {booking.lockers.map((locker: any) => {
                  return (
                    <Stack key={locker.id}>
                      <LockerButton bookingId={booking.id} locker={locker} />
                    </Stack>
                  );
                })}
              </SimpleGrid>
            </Stack>
          ) : (
            <Stack>
              <BookingCheckinButton bookingId={booking.id} />
            </Stack>
          )}
        </Box>
      </ModalBody>
      <ModalFooter
        justifyContent="center"
        h={10}
        pointerEvents={"none"}
        backgroundColor={
          !hideScrollIndication && !isCheckedIn ? "#f2f2f2" : "white"
        }
      >
        {!hideScrollIndication && !isCheckedIn && (
          <HStack
            spacing={2}
            alignItems="center"
            fontSize={11}
            color={Purple100}
          >
            <ArrowDown />
            <span>Scroll down</span>
            <ArrowDown />
          </HStack>
        )}
      </ModalFooter>
    </>
  );
};

function BookingCheckinButton({ bookingId }: { bookingId: string }) {
  const { successMessage } = useSnack();
  const [read, setRead] = useState(false);
  const query = useQueryClient();
  const { mutate, isLoading } = useMutation(checkInBooking, {
    onSuccess: () => {
      successMessage("Done");
      query.invalidateQueries([FETCH_BOOKING_QUERY, bookingId]);
    },
  });
  const { t } = useTranslation("common");
  const isDisabled = !read;

  return (
    <Stack spacing={4}>
      <Checkbox
        label={t("read_checkin_rules")}
        onChange={() => setRead(!read)}
        checked={read}
        alignItems="start"
      />
      <Button
        isDisabled={isDisabled}
        onClick={() => mutate({ bookingId })}
        isLoading={isLoading}
      >
        {t("action.save")}
      </Button>
    </Stack>
  );
}

function LockerButton({
  bookingId,
  locker,
}: {
  bookingId: string;
  locker: any;
}) {
  const { successMessage } = useSnack();
  const { mutate, isLoading } = useMutation(openLocker, {
    onSuccess: () => {
      successMessage("Done");
    },
  });
  const { t } = useTranslation("common");

  if (locker.station.type === "MANUAL") {
    return (
      <Heading fontSize="md" border="1px solid #ccc" borderRadius="md" p={2}>
        Sup Board {locker.address}
      </Heading>
    );
  }

  return (
    <Button
      size="sm"
      p="12px"
      w="full"
      display="flex"
      alignItems="center"
      alignSelf="center"
      onClick={() => mutate({ bookingId, lockerId: locker.id })}
      isLoading={isLoading}
    >
      <Stack spacing={1} direction="row">
        <LockSimpleOpen size={16} />
        <div>
          {t("booking.unlockLocker")} {locker.address}
        </div>
      </Stack>
    </Button>
  );
}
