import { Heading, Stack, Text } from "@chakra-ui/react";

export const TCNL = () => {
  return (
    <div>
      <Heading as="h1" size="md" mt={4}>
        Algemene Voorwaarden Sup Sup Club B.V.
      </Heading>
      <Stack spacing={4} mt={4}>
        <Heading size="md">Artikel 1 Definities</Heading>
        <Text>
          Deelnemer: Eenieder die een overeenkomst sluit met de Sup Sup Club
          inclusief diegene die gebruikmaakt van een door de deelnemer gehuurde
          sup, en/of indien de Deelnemer meerdere sups huurt voor anderen, deze
          andere gebruikers. Sup Sup Club: Sup Sup Club B.V., de besloten
          vennootschap met beperkte aansprakelijkheid, statutair gevestigd te
          Amsterdam en kantoorhoudende te (1013KT) Amsterdam aan de Zandhoek 22,
          geregistreerd onder nummer 81205309 bij de Kamer van Koophandel te
          Amsterdam. Deelnemersovereenkomst: De overeenkomst die de Sup Sup Club
          en een Deelnemer via de website sluiten waarbij deze Algemene
          Voorwaarden geaccepteerd worden of wanneer de Deelnemer gebruik heeft
          gemaakt van de faciliteiten van de Sup Sup Club. Persoonsgegevens:
          Alle gegevens verstrekt door Deelnemer aan de Sup Sup Club zoals
          weergegeven in de Deelnemersovereenkomst.
        </Text>
        <Heading size="md">Artikel 2 Toepasselijkheid </Heading>
        <Text>
          2.1 Deze Algemene Voorwaarden zijn van toepassing op alle
          rechtsverhoudingen tussen de Sup Sup Club en iedere Deelnemer. De
          Algemene Voorwaarden van de Deelnemer zijn niet van toepassing. 2.2
          Alle bedingen in deze Algemene Voorwaarden zijn mede opgesteld ten
          behoeve van alle bestuurders van de Sup Sup Club en alle personen die
          voor de Sup Sup Club werkzaam zijn en/of door de Sup Sup Club zijn
          ingeschakeld. 2.3 Afwijkingen van deze Algemene Voorwaarden zijn
          uitsluitend geldig indien deze uitdrukkelijk schriftelijk zijn
          overeengekomen.
        </Text>
        <Heading size="md">Artikel 3 Deelnemersovereenkomst </Heading>
        <Text>
          3.1 De Deelnemersovereenkomst tussen Deelnemer en de Sup Sup Club komt
          tot stand op het moment dat de (a) Deelnemer alle verzochte informatie
          zoals weergegeven in de aanmeldingsformulier op de website heeft
          ingevuld en de Algemene Voorwaarden heeft geaccepteerd en de Deelnemer
          de betaling voldaan heeft of (b) de Deelnemer gebruik heeft gemaakt
          van de faciliteiten van de Sup Sup Club. Indien er sprake is van losse
          verhuur dan eindigt de Deelnemersovereenkomst automatisch na de
          periode van verhuur. 3.2 De Algemene Voorwaarden en de
          Deelnemersovereenkomst vormen gezamenlijk de volledige weergave van de
          rechten en verplichtingen van de Sup Sup Club en Deelnemer en
          vervangen alle eerdere schriftelijke en mondelinge aankondigingen,
          afspraken en alle andere correspondentie. 3.3 Deelnemer zorgt ervoor
          dat alle gegevens - waarvan de Sup Sup Club aangeeft dat deze
          wenselijk zijn of waarvan Deelnemer redelijkerwijs behoort te
          begrijpen dat deze voor de uitvoering van de Deelnemersovereenkomst
          noodzakelijk zijn - tijdig aan de Sup Sup Club worden verstrekt. 3.4
          De Deelnemersovereenkomst is persoonsgebonden en Deelnemer is niet
          gerechtigd de rechten en plichten die voortvloeien uit de onder deze
          Algemene Voorwaarden gesloten Deelnemersovereenkomst geheel of
          gedeeltelijk aan derden over te dragen. 3.5 Indien de Deelnemer
          besluit een nieuw/aanvullend product bij de Sup Sup Club af te nemen
          dan blijven de voorwaarden van de Deelnemersovereenkomst en deze
          Algemene Voorwaarden onverkort van toepassing tenzij uitdrukkelijk
          schriftelijk anders is overeengekomen. 3.6. Sup Sup Club heeft het
          recht deze Algemene Voorwaarden eenzijdig te wijzigen. 3.7 De
          minimumleeftijd om zelfstandig een SUPboard te huren, een
          strippenkaart te kopen of abonnement af te sluiten is 18+.
        </Text>
        <Heading size="md">Artikel 4 De Deelnemer</Heading>
        <Text>
          De Deelnemer voldoet aan de volgende vereisten:  de Deelnemer(s)
          hebben een zwemdiploma A (of de buitenlandse equivalent hiervan); en 
          de Deelnemer(s) zijn fit en gezond. Sup Sup Club behoudt altijd het
          recht voor om Deelnemer(s) te weigeren, zonder opgaaf van reden.
        </Text>
        <Heading size="md">Artikel 5 Prijzen en betaling </Heading>
        <Text>
          5.1 De kosten voor zowel losse verhuur van de faciliteiten van de Sup
          Sup Club dienen voorafgaand aan het gebruik daarvan aan de Sup Sup
          Club te zijn voldaan. 5.2 Sup Sup Club behoudt zich het recht voor
          haar prijzen en tarieven te indexeren en wijzigen.
        </Text>
        <Heading size="md">
          Artikel 6 Aanmelding en annulering huur / lessen
        </Heading>
        <Text>
          6.1 Deelnemer dient zich voorafgaand aan de huur van een SUPboard of
          een les aan te melden via het daarvoor bestemde online
          aanmeldingssysteem. Zonder aanmelding kan niet worden deelgenomen aan
          een les of kan er geen SUPboard worden gehuurd. 6.2 Nadat een boeking
          gedaan is kan de Deelnemer zich niet uitschrijven en worden gemaakte
          kosten niet gerestitueerd.
        </Text>
        <Heading size="md">Artikel 7 Risico en Aansprakelijkheid </Heading>
        <Text>
          7.1 Sup Sup Club is alleen verantwoordelijk voor het verhuren van
          kwalitatief goede SUPboards en paddels. Sup Sup Club is dan ook alleen
          aansprakelijk als er iets aan deze SUPboards of paddels mankeert. De
          Deelnemer dient voor hij het water op gaat eerst te controleren of er
          niets mankeert aan het materiaal. Mankeert er wel wat, dan dient hij
          contact op te nemen met Sup Sup Club voordat de Deelnemer het water
          opgaat. Sup Sup Club opent dan zo snel mogelijk een andere locker met
          nieuw materieel. Sup Sup Club heeft zijn aansprakelijkheid voor een
          tekortkoming in de nakoming van de verplichtingen uit deze
          overeenkomst gelimiteerd tot het bedrag dat de verzekeraar
          daadwerkelijk uitkeert. Deze beperking geldt niet indien de schade het
          gevolg is van de grove schuld of opzet van een van de statutair
          directeuren van Sup Sup Club. 7.2 Het gebruik van de faciliteiten van
          de Sup Sup Club voor activiteiten van welke aard dan ook bij de Sup
          Sup Club is geheel voor eigen rekening en risico van de Deelnemer. 7.2
          Deelnemer accepteert dat suppen, met mogelijke kans op het in (koud)
          water vallen, mogelijke risico’s met zich meebrengt zowel voor de
          Deelnemers zelf als zijn/haar eigendommen. Deelnemer accepteert en is
          op de hoogte dat bij de huur of gebruik van een SUPboard – in het
          geval van huur – er geen begeleiding vanuit de Sup Sup Club op de
          locatie van verhuur aanwezig is. De Deelnemer vrijwaart de Sup Sup
          Club van aansprakelijkheid op enige vorm van schade of letsel en
          accepteert nadrukkelijk mogelijke gevolgen van het suppen. 7.3 Mocht
          een Deelnemer voor meerdere Deelnemers Sup’s huren, dan is de
          Deelnemer die registreert volledig verantwoordelijk en aansprakelijk
          voor de schade die zijn of haar mede Deelnemer(s) veroorzaken. Ook
          staat de Deelnemer die registreert in voor het feit dat de andere
          Deelnemer(s) voldoen aan de eisen genoemd in artikel 4. De Deelnemer
          die registreert vrijwaart Sup Sup Club daarom ook voor claims van de
          mede Deelnemers. 7.4 Deelnemer is verplicht – in het geval van een les
          of evenement - zowel vooraf, tijdens als na afloop van enige door een
          trainer begeleidde training melding te maken van zijn of haar fysieke
          gesteldheid zoals blessures, ziekten en/of andere lichamelijke
          aandoeningen en/of informatie te verschaffen waarvan deelnemer
          redelijkerwijs behoort te begrijpen dat deze voor het uitoefenen van
          oefeningen noodzakelijk is. Deelnemer is zelf verantwoordelijk voor
          het melden van deze informatie. 7.5 Zowel de Sup Sup Club als haar
          medewerkers zijn niet aansprakelijkheid voor materiele en/of
          immateriële schade als gevolg van een ongeval of letsel/blessures van
          de Deelnemer en/of derden. 7.6 De Sup Sup Club en haar medewerkers
          aanvaarden geen enkele aansprakelijkheid voor schade, verlies of
          diefstal van eigendommen van Deelnemer en/of derden. 7.7 Deelnemer is
          aansprakelijk voor schade toegebracht aan eigendommen van de Sup Sup
          Club, indien deze schade gevolg is van nalatigheid en/of schuld van de
          Deelnemer. 7.8 De uitsluitingen en beperkingen van aansprakelijkheid
          als in dit artikel vermeld, worden evenzeer bedongen voor en ten
          behoeve van ondergeschikten van de Sup Sup Club en ieder ander van
          wiens hulp de Sup Sup Club gebruik maakt bij de uitvoering van de
          Deelnemersovereenkomst.
        </Text>
        <Heading size="md">
          Artikel 8 Persoonsgegevens en privacy beleid
        </Heading>
        <Text>
          8.1 Deelnemer is zich bewust dat hij/zij bepaalde persoonsgegevens aan
          de Sup Sup Club verstrekt, zoals onder meer naam, leeftijd, adres en
          emailadres. 8.2 De eventuele verwerking van de persoonsgegevens door
          de Sup Sup Club gebeurt conform de Algemene Verordening
          Gegevensbescherming en haar privacy policy welke is terug te vinden op
          www.supsupclub.com.
        </Text>
      </Stack>
    </div>
  );
};
