import { BusinessProvider } from "common/components/business-provider";
import { Entry as EntryCommon } from "common/views/entry";
import "react-day-picker/dist/style.css";

function App() {
  return (
    <BusinessProvider>
      <EntryCommon />
    </BusinessProvider>
  );
}

export default App;
