import { HStack, Spinner, Stack } from "@chakra-ui/react";
import { ClickableCard } from "common/components/clickable-card";
import {
  fetchLocationsByAreaId,
  FETCH_LOCATIONS_BY_AREA_ID_QUERY,
} from "common/endpoints";
import { Location } from "common/types";
import { PurpleLight10 } from "design-system/colors";
import { ArrowCircleLeft } from "phosphor-react";
import { TitleMedium } from "design-system/titles";
import { MapPinLine } from "phosphor-react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useBookingFlow } from "user/hooks/use-booking-flow";
import { AREAS_PATH, LOCATIONS_PATH, getBookPath } from "user/routes";
import { UnstyledButton } from "design-system/button";

interface Props {
  isManual?: boolean;
  nextCallback?: () => void;
  backCallback?: () => void;
}

export const BookingLocations: React.FC<
  React.PropsWithChildren<Props>
> = () => {
  const { t } = useTranslation("common");
  const { id } = useParams();
  const { setBooking } = useBookingFlow();
  const navigate = useNavigate();

  const { data: locations, isLoading: isLoadingLocations } = useQuery(
    [FETCH_LOCATIONS_BY_AREA_ID_QUERY, id],
    () => {
      if (id) {
        return fetchLocationsByAreaId(id);
      }

      return null;
    }
  );

  if (isLoadingLocations) {
    return <Spinner />;
  }

  return (
    <Stack spacing={4}>
      <HStack spacing={2}>
        <UnstyledButton
          onClick={() => {
            navigate(getBookPath(AREAS_PATH));
          }}
        >
          <ArrowCircleLeft size={24} />
        </UnstyledButton>

        <TitleMedium>{t("bookingFlow.locations")}</TitleMedium>
      </HStack>
      {!locations?.length && <div>{t("bookingFlow.locations.noItems")}</div>}
      {locations.map((location: Location) => (
        <ClickableCard
          key={location.id}
          onClick={() => {
            setBooking({ locationId: location.id });
            navigate(getBookPath(`${LOCATIONS_PATH}/${location.id}`));
          }}
        >
          <Stack direction="row" spacing={4} alignItems="center">
            <MapPinLine />
            <div>
              <Stack spacing={0}>
                <div>{location.name}</div>
                <small style={{ color: PurpleLight10 }}>
                  {location.street}
                </small>
              </Stack>
            </div>
          </Stack>
        </ClickableCard>
      ))}
    </Stack>
  );
};
