import { Stack } from "@chakra-ui/react";
import {
  CalendarPlus,
  HouseSimple,
  Question,
  SignIn,
  User,
} from "phosphor-react";
import { MenuItem, MenuItemText } from "./styles";
import { useTranslation } from "react-i18next";
import { BOOK_PATH, HOME_PATH, PROFILE_PATH, SUPPORT_PATH } from "user/routes";
import { SIGN_IN_PATH } from "auth/routes";
import { useShouldCompleteProfile } from "user/utils";
import { useAuth } from "auth/hooks/use-auth";

export const Menu: React.FC<React.PropsWithChildren> = () => {
  const { t } = useTranslation("common");
  const { isLoggedIn, isOnefitAccount } = useAuth();
  const isProfileIncomplete = useShouldCompleteProfile();
  const menuClasses = isProfileIncomplete ? "disabled" : undefined;

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      height="100%"
      width="100%"
      bg="white"
    >
      <Stack direction="row" height="100%" width="100%" spacing={0}>
        <MenuItem className={menuClasses} to={HOME_PATH} end>
          <HouseSimple size={24} />
          <MenuItemText>{t("menu.home")}</MenuItemText>
        </MenuItem>
        {!isOnefitAccount && (
          <MenuItem className={menuClasses} to={BOOK_PATH}>
            <CalendarPlus size={24} />
            <MenuItemText>{t("menu.booking")}</MenuItemText>
          </MenuItem>
        )}
        <MenuItem className={menuClasses} to={SUPPORT_PATH}>
          <Question size={24} />
          <MenuItemText>{t("menu.support")}</MenuItemText>
        </MenuItem>
        {isLoggedIn ? (
          isOnefitAccount ? null : (
            <MenuItem to={PROFILE_PATH}>
              <User size={24} />
              <MenuItemText>{t("menu.profile")}</MenuItemText>
            </MenuItem>
          )
        ) : (
          <MenuItem to={SIGN_IN_PATH}>
            <SignIn size={24} />
            <MenuItemText>{t("menu.signIn")}</MenuItemText>
          </MenuItem>
        )}
      </Stack>
    </Stack>
  );
};
